<template>
  <v-form
    ref="ticketCorrectiveActionForm"
    v-model.trim="formValid"
    lazy-validation
    v-on:submit.stop.prevent="ticketCorrectiveActionForm"
  >
    <v-container
      fluid
      class="white-background client-service-forms service-report-form"
      :class="{ 'pointer-events-none': serviceForm.is_filled }"
    >
      <v-row class="mx-4">
        <v-col style="border: 1px solid darkgrey" class="mb-3" md="12">
          <v-row>
            <v-col>
              <label class="font-weight-700 font-size-18 pl-2 d-flex"
                >Select one of following
              </label>

              <v-radio-group
                v-model="attributes.selectradio"
                mandatory
                row
                hide-details
                class="ml-3 my-3"
                :readonly="!checkouttimedata"
                :class="{
                  'pointer-events-none':
                    serviceForm.is_filled || is_staff || !engineerloginform,
                }"
              >
                <v-radio
                  label="Service Call"
                  value="service-call"
                  color="cyan"
                  class="mr-10"
                ></v-radio>
                <v-radio
                  label="Inspection"
                  value="inspection"
                  color="cyan"
                  class="mr-10"
                ></v-radio>
                <v-radio
                  label="Installation"
                  value="installation"
                  color="cyan"
                  class="mr-10"
                ></v-radio>
                <v-radio
                  label="Follow-up"
                  value="follw-up"
                  color="cyan"
                  class="mr-10"
                ></v-radio>
                <v-radio
                  label="Delivery"
                  value="delivery"
                  color="cyan"
                  class="mr-10"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex align-items-center">
              <label
                class="font-weight-700 font-size-18 pl-2 d-flex text-no-wrap"
                >Maintenance Contract</label
              >
              <v-radio-group
                v-model="attributes.maintenancecontract"
                mandatory
                row
                hide-details
                class="ml-3"
                :readonly="!checkouttimedata"
                :class="{
                  'pointer-events-none':
                    serviceForm.is_filled || is_staff || !engineerloginform,
                }"
              >
                <v-radio
                  label="Yes"
                  value="yes"
                  color="cyan"
                  class="mr-10"
                ></v-radio>
                <v-radio
                  label="No"
                  value="no"
                  color="cyan"
                  class="mr-10"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

          <v-row>
            <v-col md="6">
              <label class="font-weight-700 font-size-18 pl-2"
                >Request/Fault Reported</label
              >
              <v-textarea
                v-model.trim="attributes.request_fault_reported"
                auto-grow
                dense
                filled
                :disabled="pageLoading"
                :loading="pageLoading"
                color="cyan"
                label="Request/Fault Reported"
                solo
                flat
                row-height="25"
                :readonly="!checkouttimedata"
                :class="{
                  'pointer-events-none':
                    serviceForm.is_filled || is_staff || !engineerloginform,
                }"
              ></v-textarea>
            </v-col>
            <v-col md="6">
              <label class="font-weight-700 font-size-18 pl-2"
                >Diagnose/Fault Found</label
              >
              <v-textarea
                v-model.trim="attributes.diagnose_fault_found"
                auto-grow
                dense
                filled
                :disabled="pageLoading"
                :loading="pageLoading"
                color="cyan"
                label="Diagnose/Fault Found"
                solo
                flat
                row-height="25"
                :readonly="!checkouttimedata"
                :class="{
                  'pointer-events-none':
                    serviceForm.is_filled || is_staff || !engineerloginform,
                }"
              ></v-textarea>
            </v-col>
          </v-row>

          <v-row>
            <v-col md="6">
              <label class="font-weight-700 font-size-18 pl-2"
                >Job Done / Rectified</label
              >
              <v-textarea
                v-model.trim="attributes.job_done_rectified"
                auto-grow
                dense
                filled
                :disabled="pageLoading"
                :loading="pageLoading"
                color="cyan"
                label="Job Done / Rectified"
                solo
                flat
                row-height="25"
                :readonly="!checkouttimedata"
                :class="{
                  'pointer-events-none':
                    serviceForm.is_filled || is_staff || !engineerloginform,
                }"
              ></v-textarea>
            </v-col>
            <v-col md="6">
              <label class="font-weight-700 font-size-18 pl-2"
                >Remark/request</label
              >
              <v-textarea
                v-model.trim="attributes.remark_request"
                auto-grow
                dense
                filled
                :disabled="pageLoading"
                :loading="pageLoading"
                color="cyan"
                label="Remark/request"
                solo
                flat
                row-height="25"
                :readonly="!checkouttimedata"
                :class="{
                  'pointer-events-none':
                    serviceForm.is_filled || is_staff || !engineerloginform,
                }"
              ></v-textarea>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="d-flex align-items-center">
              <label
                class="font-weight-700 font-size-18 pl-2 d-flex text-no-wrap"
                >Service ticket status</label
              >
              <v-radio-group
                v-model="attributes.service_ticket_status"
                mandatory
                row
                hide-details
                class="ml-3"
                :readonly="!checkouttimedata"
                :class="{
                  'pointer-events-none':
                    serviceForm.is_filled || is_staff || !engineerloginform,
                }"
              >
                <v-radio
                  label="Completed"
                  value="completed"
                  color="cyan"
                  class="mr-10"
                ></v-radio>
                <v-radio
                  label="Pending"
                  value="pending"
                  color="cyan"
                  class="mr-10"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { mapGetters } from "vuex";
import { ServiceFormEventBus } from "@/core/lib/service.form.lib";
import { PATCH } from "@/core/services/store/request.module";
import ValidationMixin from "@/core/plugins/validation-mixin";
//import {  CheckinCheckoutcata,} from "@/core/lib/checkin.lib";
import { currentUser } from "@/core/services/jwt.service";

export default {
  name: "service-report",
  mixins: [ValidationMixin],
  props: {
    detail: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    serviceForm: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
  },
  data: () => {
    return {
      formValid: true,
      pageLoading: true,
      checkouttimedata: false,
      engineerloginform: false,
      attributes: {
        selectradio: null,
        maintenancecontract: null,
        request_fault_reported: null,
        diagnose_fault_found: null,
        job_done_rectified: null,
        service_ticket_status: null,
        remark_request: null,
      },
    };
  },
  methods: {
    updatePreInformation() {
      const _this = this;
      try {
        if (_this.lodash.isEmpty(_this.serviceForm) === false) {
          if (_this.lodash.isEmpty(_this.serviceForm.attributes) === false) {
            _this.attributes = _this.serviceForm.attributes;
          }
        }
      } catch (error) {
        _this.logError(error);
      } finally {
        _this.pageLoading = false;
      }
    },
    saveServiceForm(action) {
      const _this = this;

      ServiceFormEventBus.$emit("start:loader");

      _this.$store
        .dispatch(PATCH, {
          url:
            "job/" +
            _this.detail.id +
            "/visit/" +
            _this.serviceForm.visit.id +
            "/service-form/" +
            _this.serviceForm.id,
          data: { attributes: _this.attributes, action },
        })
        .then(({ data }) => {
          ServiceFormEventBus.$emit("save:service-form", data);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          ServiceFormEventBus.$emit("close:loader");
        });
    },
  },
  beforeDestroy() {
    ServiceFormEventBus.$off("start:save-service-form");
  },

  computed: {
    ...mapGetters(["checkout_visit"]),
    is_staff() {
      const user = currentUser();
      const role_name = this.lodash.toLower(user.role_name);
      return role_name == "admin";
    },

    /*   is_staff_login() {
      const user = currentUser();
      const role_user = this.lodash.toLower(user.id);
    
     if(this.serviceForm.added_by.id == role_user){
        return true;
     }else{
         return false;
     }
    }, */
  },

  mounted() {
    const _this = this;

    ServiceFormEventBus.$on("start:save-service-form", (param) => {
      _this.saveServiceForm(param);
    });
    /* CheckinCheckoutcata.$on("visit-newposts-code", (param) => {

  
    if(param && param.id){
      this.checkouttimedata = true;
    }else{
      this.checkouttimedata = false;
    }
  
      
    }); */
    //console.log(this.role_engineer, "this.role_engineer");
    /* if(this.checkout_visit.engineer == this.role_engineer){
   this.engineerloginform = true;
}else{
  this.engineerloginform = false;
} */

    if (this.checkout_visit && this.checkout_visit.id) {
      this.checkouttimedata = true;
    } else {
      this.checkouttimedata = false;
    }

    const user = currentUser();

    const role_user = this.lodash.toLower(user.id);

    if (this.serviceForm.added_by.id == role_user) {
      this.engineerloginform = true;
    } else {
      this.engineerloginform = true;
    }

    _this.updatePreInformation();
  },
};
</script>
