<template>
  <v-app>
    <v-container
      v-if="isVerified"
      class="bg-white h-100 py-0"
      style="width: 1024px"
    >
      <v-row>
        <template v-if="isSubmitted || isRejected">
          <v-col v-if="isSubmitted" md="12" class="px-10 py-0">
            <div class="quotation-approved my-20">
              <div class="card">
                <div
                  style="
                    border-radius: 200px;
                    height: 200px;
                    width: 200px;
                    background: #f8faf5;
                    margin: 0 auto;
                  "
                >
                  <i class="checkmark">✓</i>
                </div>
                <h1>Success</h1>
                <p>
                  We received your approval against quotation #
                  {{ quotation_detail.barcode }}<br />
                  we'll be in touch shortly!<br />
                  Thank You!
                </p>
              </div>
            </div>
          </v-col>
          <v-col v-if="isRejected" md="12" class="px-10 py-0">
            <div class="quotation-approved quotation-rejected my-20">
              <div class="card">
                <div
                  style="
                    border-radius: 200px;
                    height: 200px;
                    width: 200px;
                    background: #f8faf5;
                    margin: 0 auto;
                  "
                >
                  <i class="checkmark">✓</i>
                </div>
                <h1>Success</h1>
                <p>
                  We received your rejection against quotation #
                  {{ quotation_detail.barcode }}<br />
                  we'll be in touch shortly!<br />
                  Thank You!
                </p>
              </div>
            </div>
          </v-col>
        </template>
        <v-col v-else md="12" class="px-10 py-0">
          <table width="100%">
            <tr>
              <td width="35%" align="bottom" class="p-0">
                <v-img
                  lazy-src="https://fakeimg.pl/745x135"
                  max-height="100"
                  max-width="275"
                  src="https://bthrust-fsm.s3.ap-southeast-1.amazonaws.com/SL0622ENE/files/image/png/tIAOW40CZhJrXb1ICcwLaUOiSa2EI6eQI6BR8X2y.png"
                  alt="logo"
                />
              </td>
              <td class="p-0">
                <table width="100%">
                  <tr>
                    <td colspan="2" class="p-0">
                      <span class="font-weight-700 font-19"
                        >HYPER COMMUNICATIONS PTE LTD</span
                      >
                    </td>
                  </tr>
                  <tr>
                    <td class="p-0">
                      Website :
                      <a href="https://www.hypercom.sg/">www.hypercom.sg</a>
                    </td>
                    <td class="p-0">
                      E-shop:
                      <a href="https://eshop.hypercom.sg"
                        >https://eshop.hypercom.sg</a
                      >
                    </td>
                  </tr>
                  <tr>
                    <td class="p-0">Hotline: +65-6336 0055</td>
                    <td class="p-0">WhatsApp: +65-8668 0055</td>
                  </tr>
                  <tr>
                    <td class="p-0" colspan="2">
                      1093 Lower Delta Road #04-17 Singapore 169204
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
          <table width="100%">
            <tr>
              <td class="py-1" width="60%">
                <div
                  style="
                    border: 1px solid #042e76;
                    border-radius: 20px;
                    padding: 0px 10px;
                  "
                >
                  <table width="100%">
                    <tr>
                      <td class="py-1" valign="top" width="19%">Company</td>
                      <td class="py-1" valign="top" width="6%">:</td>
                      <td class="py-1">
                        <template
                          v-if="quotation_detail && quotation_detail.customer"
                        >
                          {{ quotation_detail.customer.company_name }}
                        </template>
                      </td>
                    </tr>
                    <tr>
                      <td class="py-1">Address</td>
                      <td class="py-1" valign="top">:</td>
                      <td class="py-1">
                        <template
                          v-if="quotation_detail && quotation_detail.billing"
                        >
                          {{ fullAddress(quotation_detail.billing) }}
                        </template>
                      </td>
                    </tr>
                    <tr>
                      <td class="py-1">Attention</td>
                      <td class="py-1" valign="top">:</td>
                      <td class="py-1">
                        <template
                          v-if="
                            quotation_detail && quotation_detail.property_person
                          "
                        >
                          {{ quotation_detail.property_person.display_name }}
                        </template>
                      </td>
                    </tr>
                    <tr>
                      <td class="py-1">Telephone</td>
                      <td class="py-1" valign="top">:</td>
                      <td class="py-1">
                        <template
                          v-if="
                            quotation_detail && quotation_detail.property_person
                          "
                        >
                          {{ quotation_detail.property_person.primary_phone }}
                        </template>
                      </td>
                    </tr>
                    <tr>
                      <td class="py-1">Mobile</td>
                      <td class="py-1" valign="top">:</td>
                      <td class="py-1">
                        <template
                          v-if="
                            quotation_detail && quotation_detail.property_person
                          "
                        >
                          {{ quotation_detail.property_person.primary_phone }}
                        </template>
                      </td>
                    </tr>
                    <tr>
                      <td class="py-1">Email</td>
                      <td class="py-1" valign="top">:</td>
                      <td class="py-1">
                        <template
                          v-if="
                            quotation_detail && quotation_detail.property_person
                          "
                        >
                          {{ quotation_detail.property_person.primary_email }}
                        </template>
                      </td>
                    </tr>
                  </table>
                </div>
              </td>
              <td class="py-1" width="40%" style="padding-left: 30px">
                <table width="100%">
                  <tr>
                    <td class="py-1" colspan="2">
                      <strong style="font-size: 18px" class="font-weight-700"
                        >Quotation
                      </strong>
                    </td>
                  </tr>
                  <tr>
                    <td class="py-1" width="23%">Date</td>
                    <td class="py-1" width="10%">:</td>
                    <td class="py-1">{{ currentDate }}</td>
                  </tr>
                  <tr>
                    <td class="py-1">Quote Ref</td>
                    <td class="py-1">:</td>
                    <td class="py-1">
                      {{ quotation_detail.barcode }}
                    </td>
                  </tr>
                  <tr>
                    <td class="py-1">Your Ref.</td>
                    <td class="py-1">:</td>
                    <td class="py-1">{{ quotation_detail.reference }}</td>
                  </tr>

                  <tr>
                    <td class="py-1">Sale by</td>
                    <td class="py-1">:</td>
                    <td class="py-1">
                      {{ quotation_detail.sales }}
                    </td>
                  </tr>
                  <tr>
                    <td class="py-1">Quote by</td>
                    <td class="py-1">:</td>
                    <td class="py-1">
                      {{ prepare && prepared.display_name }}
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td
                class="py-1 font-weight-700"
                colspan="2"
                style="
                  padding: 5px;
                  border-bottom: 1px solid #042e76;
                  border-top: 1px solid #042e76;
                "
              >
                Subject: {{ quotation_detail.job_title }}
              </td>
            </tr>
          </table>

          <table width="100%">
            <tbody>
              <tr>
                <td colspan="7" height="6px"></td>
              </tr>
              <tr class="line-items fw-700">
                <td
                  width="70px"
                  class="light-gray-background customer-font-bold customer-border-top customer-border-bottom customer-border-left fw-700"
                  align="center"
                  valign="center"
                >
                  SN.
                </td>
                <td
                  class="light-gray-background customer-font-bold customer-border-top customer-border-bottom customer-border-left customer-border-right fw-700"
                  align="center"
                  valign="center"
                >
                  Model
                </td>
                <td
                  class="light-gray-background customer-font-bold customer-border-top customer-border-bottom customer-border-left customer-border-right fw-700"
                  align="center"
                  valign="center"
                >
                  Description
                </td>
                <td
                  class="light-gray-background customer-font-bold customer-border-top customer-border-bottom customer-border-left customer-border-right fw-700"
                  align="center"
                  valign="center"
                >
                  Qty
                </td>
                <td
                  class="light-gray-background customer-font-bold customer-border-top customer-border-bottom customer-border-left customer-border-right fw-700"
                  align="center"
                  valign="center"
                >
                  UOM
                </td>
                <td
                  class="light-gray-background customer-font-bold customer-border-top customer-border-bottom customer-border-right fw-700"
                  align="center"
                  valign="center"
                  width="100px"
                >
                  Unit Price ({{ quotation_detail.currency }})
                </td>
                <td
                  class="light-gray-background customer-font-bold customer-border-top customer-border-bottom customer-border-right fw-700"
                  align="center"
                  valign="center"
                  width="100px"
                >
                  Amount ({{ quotation_detail.currency }})
                </td>
              </tr>

              <tr
                v-for="(line_item, index) in selected_products"
                :key="`products-${index}`"
                class="line-items"
              >
                <td
                  valign="top"
                  height="25px"
                  width="10px"
                  class="customer-border-bottom customer-border-left"
                  align="center"
                  :style="
                    selected_products.length - 1 == index ? 'height:150px' : ''
                  "
                >
                  {{ index + 1 }}.
                </td>
                <td
                  valign="top"
                  height="25px"
                  class="customer-border-bottom customer-border-left"
                  align="left"
                >
                  <template v-if="line_item.product">
                    {{ line_item.product }}
                  </template>
                </td>
                <td
                  valign="top"
                  height="25px"
                  class="customer-border-bottom customer-border-left"
                  align="left"
                >
                  <template v-if="line_item.description">
                    <div
                      class="pre-formatted"
                      v-html="line_item.description"
                    ></div>
                  </template>
                </td>
                <td
                  valign="top"
                  height="25px"
                  class="customer-border-bottom customer-border-left"
                  align="center"
                >
                  {{ line_item.quantity }}
                </td>
                <td
                  valign="top"
                  height="25px"
                  class="customer-border-bottom customer-border-left"
                  align="center"
                >
                  {{ line_item.uom }}
                </td>
                <td
                  valign="top"
                  height="25px"
                  class="customer-border-bottom customer-border-left customer-border-right"
                  align="center"
                >
                  {{ formatMoney(line_item.rate) }}
                </td>
                <td
                  valign="top"
                  height="25px"
                  class="customer-border-bottom customer-border-left customer-border-right"
                  align="center"
                >
                  {{ formatMoney(line_item.total) }}
                </td>
              </tr>
              <tr class="line-items">
                <td
                  class="customer-border-top customer-border-left customer-border-right customer-border-bottom fw-700"
                  colspan="6"
                  align="right"
                  valign="center"
                >
                  Sub Total:&nbsp;&nbsp;
                </td>
                <td
                  class="customer-border-top customer-border-right customer-border-bottom fw-700"
                  align="center"
                  valign="center"
                >
                  {{ formatMoney(quotation_detail.sub_total) }}
                </td>
              </tr>
              <tr v-if="quotation_detail.discount_amount" class="line-items">
                <td
                  class="customer-border-left customer-border-right customer-border-bottom fw-700"
                  colspan="6"
                  align="right"
                  valign="center"
                >
                  Discount:&nbsp;&nbsp;
                </td>
                <td
                  class="customer-border-right customer-border-bottom fw-700"
                  align="center"
                  valign="center"
                >
                  {{ formatMoney(quotation_detail.discount_amount) }}
                </td>
              </tr>
              <tr v-if="quotation_detail.tax_amount" class="line-items">
                <td
                  class="customer-border-bottom fw-700 customer-border-left customer-border-right"
                  colspan="6"
                  align="right"
                  valign="center"
                >
                  Tax [GST {{ quotation_detail.tax_value }} %]:&nbsp;&nbsp;
                </td>
                <td
                  class="customer-border-bottom fw-700 customer-border-right"
                  align="center"
                  valign="center"
                >
                  {{ formatMoney(quotation_detail.tax_amount) }}
                </td>
              </tr>
              <tr v-if="quotation_detail.adjustment" class="line-items">
                <td
                  class="customer-border-bottom fw-700 customer-border-left customer-border-right"
                  colspan="6"
                  align="right"
                  valign="center"
                >
                  Adjustment:&nbsp;&nbsp;
                </td>
                <td
                  class="customer-border-bottom fw-700 customer-border-right"
                  align="center"
                  valign="center"
                >
                  {{ formatMoney(quotation_detail.adjustment) }}
                </td>
              </tr>
              <tr class="line-items">
                <td
                  class="customer-border-bottom fw-700 customer-font-bold customer-border-bottom customer-border-left customer-border-right"
                  colspan="6"
                  align="right"
                  valign="center"
                >
                  Grand Total:&nbsp;&nbsp;
                </td>
                <td
                  class="customer-border-bottom fw-700 customer-font-bold customer-border-bottom customer-border-right"
                  align="center"
                  valign="center"
                >
                  {{ formatMoney(quotation_detail.total) }}
                </td>
              </tr>
              <tr>
                <td colspan="10" height="10px"></td>
              </tr>
              <tr>
                <td colspan="10" height="10px"></td>
              </tr>
            </tbody>
          </table>

          <table width="100%" cellspacing="0" cellpadding="2">
            <tr>
              <td>
                <span class="font-weight-700" style="border-bottom: 1px solid"
                  >Professional Services &amp; Installation Services</span
                ><br /><br />
                <span>
                  1&#41; Any variation due to site requirements shall be charged
                  under variation order.<br />
                  2&#41; Standard Installation, testing and commissioning are
                  not inclusive of cable tray, metal trucking and PVC conduit
                  above the ceiling or under the floor, hacking &amp; make good
                  of floor and wall. <br />
                  3&#41; The above installation / testing and commissioning
                  quoted only applicable to normal office environment, job
                  requiring high ladder or scaffolding will be under variation
                  cost.<br />
                  4&#41; Work quoted are for labour during office hours, Monday
                  to Friday (9am to 5pm).<br />
                  5&#41; Power points for all supplied equipment shall be
                  provided by client.<br />
                  6&#41; Client shall provide own internet access for remote
                  viewing &amp; internal IT support where necessary.<br />
                  7&#41; Any Existing cabling labels shall be properly labelled
                  by existing vendor.</span
                >
              </td>
            </tr>
          </table>

          <table width="100%">
            <tr>
              <td colspan="3">
                <span class="font-weight-700" style="border-bottom: 1px solid"
                  >Term &amp; Condition</span
                >
              </td>
            </tr>
            <tr>
              <td class="py-1" width="12%">Validity</td>
              <td width="3%">:</td>
              <td class="py-1">
                {{ quotation_detail.validity }}
              </td>
            </tr>
            <tr>
              <td class="py-1">Warranty</td>
              <td class="py-1">:</td>
              <td class="py-1">
                {{ quotation_detail.warranty }}
              </td>
            </tr>
            <tr>
              <td class="py-1">Delivery</td>
              <td class="py-1">:</td>
              <td class="py-1">
                {{ quotation_detail.delivery }}
              </td>
            </tr>
            <tr>
              <td class="py-1">Payment</td>
              <td class="py-1">:</td>
              <td class="py-1">{{ quotation_detail.payment }}</td>
            </tr>
            <tr>
              <td class="py-1">Cancellation</td>
              <td class="py-1">:</td>
              <td class="py-1">
                {{ quotation_detail.cancellation }}
              </td>
            </tr>
            <tr>
              <td class="py-1">Deposit</td>
              <td class="py-1">:</td>
              <td class="py-1">{{ quotation_detail.deposit }}</td>
            </tr>
            <tr>
              <td class="py-1">Note</td>
              <td class="py-1">:</td>
              <td class="py-1">
                Equipment supplied remains the property of "Hyper Communications
                Pte Ltd" until full payment is made. Interest of 3% per month
                will be imposed on all outstanding amount due
              </td>
            </tr>
            <tr>
              <td colspan="3"><br /></td>
            </tr>
            <tr>
              <td colspan="3">
                Should there be further clarifications required, please do not
                hesitate to contact the undersigned. We will gladly do our best
                to help you.
              </td>
            </tr>
          </table>

          <table
            v-if="$route.query['security-code']"
            width="100%"
            style="table-layout: fixed"
          >
            <thead class="fw-700">
              <tr class="signature-pad" id="signature-pad">
                <td valign="top">
                  <span>Attachment</span><br />
                  <FileTemplate
                    allowUpload
                    isMinDisplay
                    v-on:file:updated="updateAttachments"
                  ></FileTemplate>
                </td>
              </tr>
            </thead>
          </table>

          <table width="100%" style="table-layout: fixed">
            <thead class="fw-700">
              <tr class="signature-pad" id="signature-pad">
                <td width="33%" valign="top">
                  <span
                    >Yours faithfully <br />{{
                      company_detail.company_name
                    }}</span
                  ><br />
                  <img
                    class="my-2"
                    style="max-width: 280px; max-height: 100px"
                    v-if="prepared && prepared.signature"
                    :src="prepared.signature"
                    alt="prepared by"
                  />
                  <hr />
                </td>
                <td width="33%" valign="top"></td>
                <td width="33%" valign="top">
                  <span>Confirmed and Accepted by:</span>
                  <div
                    ref="customer_signature_div"
                    style="width: 280px"
                    class="custom-border-grey-dashed signature-pad-wrap"
                  >
                    <canvas
                      ref="customer_signature"
                      style="width: 280px; height: 120px !important"
                    ></canvas>
                  </div>
                  <hr />
                  <div style="position: relative">
                    <v-btn
                      style="position: absolute; bottom: 0px; right: -14px"
                      content="Click here to clear signature"
                      v-tippy="{ arrow: true, placement: 'top' }"
                      icon
                      small
                      v-on:click="customer_signature.clear()"
                    >
                      <v-icon>mdi-close-circle-outline</v-icon>
                    </v-btn>
                  </div>
                </td>
              </tr>

              <tr>
                <td width="33%">
                  <div>Mobile : {{ company_detail.phone_number }}</div>
                  <div>Email : {{ company_detail.email_address }}</div>
                </td>
                <td width="33%">
                  <div></div>
                </td>
                <td width="33%">
                  <div>Authorized Signature &amp; Signed Date</div>
                  <div>Name : {{ billing_person.display_name }}</div>
                  <div>Date : {{ formatDate(new Date()) }}</div>
                </td>
              </tr>
              <tr>
                <td height="80px"></td>
              </tr>
            </thead>
          </table>
          <table width="100%">
            <tfoot>
              <tr>
                <td
                  height="30px"
                  colspan="10"
                  align="center"
                  valign="center"
                  class="custom-border-top"
                  style="font-size: 10px"
                >
                  {{ company_detail.street_1 }}, {{ company_detail.street_2 }},
                  {{ company_detail.landmark }}
                  {{ company_detail.zip_code }} Tel:
                  {{ company_detail.phone_number }} •
                  {{ company_detail.website_url }} •
                  {{ company_detail.email_address }}
                </td>
              </tr>
            </tfoot>
          </table>
          <div style="position: relative" class="bg-white">
            <div
              style="position: absolute; width: 100%; bottom: 50px"
              class="text-right"
            >
              <v-btn
                v-on:click="
                  action_type = 'accept';
                  action_dialog = true;
                "
                color="cyan white--text"
                class="custom-bold-button"
                :disabled="valid_signature"
                >Accept</v-btn
              >
              <v-btn
                v-on:click="
                  action_type = 'reject';
                  action_dialog = true;
                "
                color="red white--text lighten-1"
                class="ml-4 custom-bold-button"
                >Reject</v-btn
              >
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <Dialog :common-dialog="dialog">
      <template v-slot:title> Verification </template>
      <template v-slot:body>
        <v-form
          ref="verificationForm"
          v-model.trim="formValid"
          lazy-validation
          v-on:submit.stop.prevent="verifyCustomer"
        >
          <v-row>
            <v-col md="12">
              <v-text-field
                v-model.trim="verificationCode"
                dense
                filled
                label="Security Code"
                solo
                flat
                :disabled="formLoading || pageLoading"
                :loading="formLoading"
                color="cyan"
                :rules="[
                  validateRules.required(verificationCode, 'Security Code'),
                  validateRules.minLength(verificationCode, 'Security Code', 4),
                  validateRules.maxLength(
                    verificationCode,
                    'Security Code',
                    10
                  ),
                ]"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="!formValid || formLoading || pageLoading"
          :loading="formLoading"
          class="mx-2 custom-grey-border custom-bold-button white--text"
          color="cyan"
          v-on:click="verifyCustomer"
        >
          Submit
        </v-btn>
      </template>
    </Dialog>
    <Dialog :common-dialog="action_dialog">
      <template v-slot:title> Confirmation </template>
      <template v-slot:body>
        <v-container>
          <p class="font-size-19 font-weight-600 mx-2 my-0">
            Are you sure, you want to
            <span
              class="font-weight-700"
              :class="{
                'red--text text--lighten-1': action_type == 'reject',
                'green--text text--lighten-1': action_type == 'accept',
              }"
              >{{ action_type }}</span
            >
            this quotation?
          </p>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn
          class="mx-2 custom-grey-border custom-bold-button white--text"
          color="cyan"
          v-on:click="action_submit()"
          :disabled="formLoading"
          :loading="formLoading"
        >
          Submit
        </v-btn>
        <v-btn
          :disabled="formLoading"
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="action_dialog = false"
        >
          Cancel
        </v-btn>
      </template>
    </Dialog>
    <template v-if="validationErrorBag.length">
      <v-snackbar
        :timeout="5000"
        :value="true"
        top
        color="error"
        right
        v-for="(error, index) in validationErrorBag"
        :key="index"
      >
        {{ error }}
      </v-snackbar>
    </template>
  </v-app>
</template>
<style type="text/css" scoped>
.quotation {
  font-weight: 600;
  text-decoration: underline;
}
.customer-font-bold {
  font-weight: 600;
}
.w-100px {
  width: 100px;
  display: inline-block;
}
.fw-700 {
  font-weight: 700;
}
.mr-5 {
  margin-right: 20px;
}
.fw-600 {
  font-weight: 600;
}
.customer-border-top {
  border-top: solid 2px #000;
}
.customer-border-bottom {
  border-bottom: solid 2px #000;
}
.customer-border-right {
  border-right: solid 2px #000;
}
.customer-border-left {
  border-left: solid 2px #000;
}
.line-items td {
  padding: 4px;
}
table > tbody > tr.signature-pad > td {
  width: 50% !important;
}
.quotation-approved {
  text-align: center;
  padding: 40px 0;
  background: #ebf0f5;
}
.signature-pad-wrap {
  height: 100px !important;
  margin-top: 4px;
}
.quotation-approved h1 {
  color: #88b04b;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-weight: 900;
  font-size: 40px;
  margin-bottom: 10px;
}
.quotation-approved.quotation-rejected h1 {
  color: #f34747;
}
.quotation-approved p {
  color: #404f5e;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  margin: 0;
}
.quotation-approved i {
  color: #9abc66;
  font-size: 100px;
  line-height: 200px;
  margin-left: -15px;
}
.quotation-approved.quotation-rejected i {
  color: #f34747;
  font-size: 100px;
  line-height: 200px;
  margin-left: -15px;
}
.quotation-approved .card {
  background: white;
  padding: 60px;
  border-radius: 4px;
  box-shadow: 0 2px 3px #c8d0d8;
  display: inline-block;
  margin: 0 auto;
}
</style>
<script>
import Dialog from "@/view/pages/partials/Dialog.vue";
import ValidationMixin from "@/core/plugins/validation-mixin";
import {
  VERIFY_QUOTATION_CUSTOMER,
  QUERY,
} from "@/core/services/store/request.module";
import CommonMixin from "@/core/plugins/common-mixin.js";
import SignaturePad from "signature_pad";
import { filter } from "lodash";
import ObjectPath from "object-path";
import FileTemplate from "@/view/pages/partials/FileTemplate.vue";
import moment from "moment-timezone";

export default {
  name: "quotation-approve",
  mixins: [ValidationMixin, CommonMixin],
  data: () => {
    return {
      dialog: true,
      formValid: true,
      currentDate: moment().format("DD-MM-YYYY"),
      formLoading: false,
      isVerified: false,
      isSubmitted: false,
      isRejected: false,
      pageLoading: false,
      action_dialog: false,
      action_type: null,
      tokenNotFound: false,
      verificationCode: null,
      attachments: [],
      securityToken: null,
      today_date: null,
      customer: {},
      billing: {},
      billing_person: {},
      approver: {},
      prepared: {},
      selected_products: [],
      selected_equipments: [],
      customer_signature: null,
      validationErrorBag: new Array(),
      quotation_detail: new Object({
        id: null,
        barcode: null,
        customer: {
          display_name: null,
          company_name: null,
        },
        billing: {
          unit_no: null,
          street_1: null,
          street_2: null,
          zip_code: null,
          country: {
            name: null,
          },
          property_address: null,
        },
        job_title: null,
        sales: null,
        attention: null,
        client_remark: null,
        term_conditions: null,
        additional_remarks: null,
        total: null,
        sub_total: null,
        discount_value: null,
        date: null,
        date_formatted: null,
        property_person: {
          primary_phone: null,
          primary_email: null,
          full_name: null,
        },
        line_items: [
          {
            id: null,
            description: null,
            quantity: null,
            product: {
              name: null,
              unit: {
                id: null,
                text: null,
              },
            },
            sub_total: null,
            discount: null,
          },
        ],
      }),
      company_detail: new Object(),
      header_footer: new Object(),
      sales_signature: new Object(),
    };
  },
  components: {
    Dialog,
    FileTemplate,
  },
  methods: {
    fullAddress(address) {
      let addressArray = [];
      if (address.street_1) {
        addressArray.push(address.street_1);
      }
      if (address.street_2) {
        addressArray.push(address.street_2);
      }
      if (address.zip_code) {
        addressArray.push(address.zip_code);
      }
      if (address.country_name) {
        addressArray.push(address.country_name);
      }
      return addressArray.join(", ");
    },
    updateAttachments(param) {
      this.attachments = param;
    },
    action_submit() {
      this.submitSignature(this.action_type);
    },
    getSubTotalAmount(sub_total, discount) {
      return this.formatMoney(this.lodash.sum([sub_total, discount]));
    },
    submitSignature(action) {
      const _this = this;

      if (_this.customer_signature.isEmpty() && action != "reject") {
        return false;
      }

      _this.formLoading = true;
      _this.isSubmitted = false;
      _this.isRejected = false;

      _this.validationErrorBag = new Array();

      _this.$store
        .dispatch(VERIFY_QUOTATION_CUSTOMER, {
          url: "quotation/customer/submit",
          data: {
            customer: _this.customer_signature.toDataURL(),
            code: _this.verificationCode,
            action: action,
            attachments: _this.attachments,
          },
          token: _this.securityToken,
        })
        .then(() => {
          _this.action_dialog = false;
          if (action == "reject") {
            _this.isRejected = true;
          } else {
            _this.isSubmitted = true;
          }
        })
        .catch(({ response }) => {
          if (response && response.data && response.data.message) {
            _this.validationErrorBag = response.data.message.split("<br>");
          } else {
            _this.validationErrorBag.push(
              "Something went wrong, Please try again later."
            );
          }
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
    initLineItems(quotation_line_items) {
      this.selected_products = [];
      this.selected_equipments = [];

      /*'product_type', ['goods', 'service', 'equipment'*/

      const products = filter(quotation_line_items, { product_type: "goods" });
      const services = filter(quotation_line_items, {
        product_type: "service",
      });
      const equipments = filter(quotation_line_items, {
        product_type: "equipment",
      });

      if (products.length) {
        for (let i = 0; i < products.length; i++) {
          this.selected_products.push({
            status: true,
            id: products[i].id,
            visit_barcode: products[i].visit_barcode,
            group: products[i].group,
            group_primary: products[i].group_primary,
            product: products[i].product,
            product_id: products[i].product_id,
            serial_no: products[i].serial_no,
            eq_model: products[i].eq_model,
            location: products[i].location,
            product_type: products[i].product_type,
            has_warranty: products[i].has_warranty,
            to_equipment: products[i].to_equipment,
            warranty: products[i].warranty,
            description: products[i].description,
            rate: products[i].rate,
            quantity: products[i].quantity,
            uom: products[i].uom,
            total: products[i].total,
          });
        }
      }

      if (services.length) {
        for (let i = 0; i < services.length; i++) {
          this.selected_products.push({
            status: true,
            id: services[i].id,
            visit_barcode: services[i].visit_barcode,
            group: services[i].group,
            group_primary: services[i].group_primary,
            product: services[i].product,
            product_id: services[i].product_id,
            serial_no: services[i].serial_no,
            eq_model: services[i].eq_model,
            location: services[i].location,
            product_type: services[i].product_type,
            has_warranty: services[i].has_warranty,
            to_equipment: services[i].to_equipment,
            warranty: services[i].warranty,
            description: services[i].description,
            rate: services[i].rate,
            quantity: services[i].quantity,
            uom: services[i].uom,
            total: services[i].total,
          });
        }
      }

      if (equipments.length) {
        for (let z = 0; z < equipments.length; z++) {
          this.selected_equipments.push({
            status: true,
            id: equipments[z].id,
            visit_barcode: equipments[z].visit_barcode,
            group: equipments[z].group,
            group_primary: equipments[z].group_primary,
            product: equipments[z].product,
            product_id: equipments[z].product_id,
            serial_no: equipments[z].serial_no,
            eq_model: equipments[z].eq_model,
            location: equipments[z].location,
            product_type: equipments[z].product_type,
            has_warranty: equipments[z].has_warranty,
            to_equipment: equipments[z].to_equipment,
            warranty: equipments[z].warranty,
            description: equipments[z].description,
            rate: equipments[z].rate,
            quantity: equipments[z].quantity,
            uom: equipments[z].uom,
            total: equipments[z].total,
          });
        }
      }
    },
    verifyCustomer() {
      const _this = this;

      if (!_this.$refs.verificationForm.validate()) {
        return false;
      }

      _this.formLoading = true;
      _this.isVerified = false;

      _this.validationErrorBag = new Array();

      _this.$store
        .dispatch(VERIFY_QUOTATION_CUSTOMER, {
          url: "quotation/verify",
          data: { code: _this.verificationCode },
          token: _this.securityToken,
        })
        .then(({ data }) => {
          const status = ObjectPath.get(data, "quotation_detail.status", 0);

          if (status == 3) {
            _this.isRejected = true;
          } else if (status == 2) {
            _this.isSubmitted = true;
          }

          _this.quotation_detail = data.quotation_detail;
          _this.getLineItems({ quotation: _this.quotation_detail.id });
          _this.company_detail = data.company_detail;

          _this.customer = ObjectPath.get(
            data,
            "quotation_detail.customer",
            {}
          );
          _this.billing = ObjectPath.get(data, "quotation_detail.billing", {});
          _this.billing_person = ObjectPath.get(
            data,
            "quotation_detail.billing_person",
            {}
          );
          _this.approver = ObjectPath.get(data, "approver", {});
          _this.prepared = ObjectPath.get(data, "prepared", {});

          _this.header_footer = data.header_footer;
          _this.sales_signature = data.sales_signature;
          _this.today_date = data.today_date;
          _this.dialog = false;
          _this.isVerified = true;
          _this.initSignature();
        })
        .catch(({ response }) => {
          if (response && response.data && response.data.message) {
            _this.validationErrorBag = response.data.message.split("<br>");
          } else {
            _this.validationErrorBag.push(
              "Something went wrong, Please try again later."
            );
          }
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
    getLineItems(data) {
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "line-item/new",
          data,
        })
        .then(({ data }) => {
          if (data.length) {
            const result = [];

            for (let i = 0; i < data.length; i++) {
              result.push({
                id: data[i].id,
                group: data[i].group,
                group_primary: data[i].group_primary,
                product: data[i].product,
                product_id: data[i].product_id,
                serial_no: data[i].serial_no,
                eq_model: data[i].eq_model,
                location: data[i].location,
                product_type: data[i].product_type,
                to_equipment: data[i].to_equipment,
                has_warranty: data[i].has_warranty,
                warranty: data[i].warranty,
                description: data[i].description,
                rate: data[i].rate,
                quantity: data[i].quantity,
                uom: data[i].uom,
                total: data[i].total,
                visit_barcode: data[i].visit_barcode,
              });
            }

            _this.$nextTick(() => {
              _this.initLineItems(result);
            });
          }
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    initSignature() {
      const _this = this;
      _this.$nextTick(() => {
        let ccanvas = _this.$refs["customer_signature"];
        let ccanvasDiv = _this.$refs["customer_signature_div"];
        // let cparentDiv = document.getElementById("signature-pad");
        let cparentWidth = 280; //cparentDiv.offsetWidth / 2;
        ccanvas.setAttribute("width", cparentWidth);
        ccanvasDiv.setAttribute("style", "width:" + cparentWidth + "px");
        _this.customer_signature = new SignaturePad(ccanvas);
      });
    },
  },
  mounted() {
    const _this = this;

    if (_this.verificationCode) {
      _this.verifyCustomer();
    }
  },
  created() {
    const _this = this;

    if (_this.$route.query && _this.$route.query.token) {
      _this.securityToken = _this.$route.query.token;
      _this.verificationCode = _this.$route.query["security-code"];
    } else {
      _this.dialog = false;
      _this.tokenNotFound = true;
    }
  },
  computed: {
    valid_signature() {
      return this.customer_signature
        ? this.customer_signature.isEmpty()
        : false;
    },
  },
};
</script>
