<template>
  <DetailTemplate
    :customClass="'lead-detail detail-page'"
    v-if="getPermission('lead:view')"
  >
    <template v-slot:header-title>
      <div class="d-flex">
        <template v-if="pageLoading">
          <v-skeleton-loader
            class="custom-skeleton height-40px custom-skeleton-full-width"
            width="600"
            type="text"
          >
          </v-skeleton-loader>
          <v-skeleton-loader
            class="custom-skeleton height-30px my-auto mx-2 custom-skeleton-full-width"
            width="100"
            type="text"
          >
          </v-skeleton-loader>
        </template>
        <template v-else>
          <h1 class="form-title custom-nowrap-ellipsis text-capitalize mb-0">
            {{ leadTitle }}
          </h1>
          <CustomStatus
            :status="leadDetail.status"
            endpoint="leads/status"
          ></CustomStatus>
        </template>
      </div>
    </template>
    <template v-slot:header-action>
      <v-btn
        :disabled="!formValid || formLoading || pageLoading"
        :loading="formLoading"
        class="mx-2 custom-bold-button white--text"
        color="red lighted-1"
        v-on:click="updateMoreAction('delete')"
      >
        Delete <v-icon small>mdi-delete</v-icon>
      </v-btn>
      <v-menu
        transition="slide-y-transition"
        bottom
        content-class="custom-menu-list"
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :disabled="!formValid || formLoading || pageLoading"
            :loading="formLoading"
            class="mx-2 custom-bold-button white--text"
            color="cyan"
            v-bind="attrs"
            v-on="on"
          >
            More... <v-icon right>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <template v-for="(more, index) in moreActions">
            <v-list-item
              link
              v-on:click="updateMoreAction(more.action)"
              :key="index"
              :disabled="more.disabled"
            >
              <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                <v-icon class="icon-default-blue">{{ more.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="font-weight-500 font-size-14">{{
                more.title
              }}</v-list-item-title>
            </v-list-item>
          </template>
        </v-list>
      </v-menu>
      <v-btn
        class="mx-2 custom-grey-border custom-bold-button"
        v-on:click="goBack"
      >
        <v-icon small left>mdi-keyboard-backspace</v-icon>
        Back
      </v-btn>
    </template>
    <template v-slot:body>
      <v-tabs
        v-model="leadTab"
        background-color="transparent"
        color="cyan"
        class="custom-tab-transparent"
      >
        <template v-for="(tab, index) in tabs">
          <v-tab
            class="font-size-16 font-weight-600 px-8"
            :key="index"
            :href="'#' + tab.key"
            :disabled="tab.disabled"
          >
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
              <!--begin::Svg Icon-->
              <inline-svg
                :src="$assetURL('media/custom-svg/' + tab.key + '.svg')"
              />
              <!--end::Svg Icon-->
            </span>
            {{ tab.title }}
          </v-tab>
        </template>
      </v-tabs>
      <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll"
        style="max-height: 89vh position: relative"
      >
        <v-tabs-items v-model="leadTab">
          <v-tab-item v-if="getPermission('lead:view')" value="lead">
            <v-container fluid>
              <v-row>
                <v-col md="6">
                  <table width="100%">
                    <tr>
                      <td width="200" class="pb-2 font-size-18 text-capitalize">
                        Lead Title
                      </td>
                      <td
                        class="pb-2 font-size-18 font-weight-600 text-capitalize"
                      >
                        {{ leadDetail.title }}
                      </td>
                    </tr>
                    <tr>
                      <td width="200" class="pb-2 font-size-18 text-capitalize">
                        Customer Name
                      </td>
                      <td
                        class="pb-2 font-size-18 font-weight-600 text-capitalize"
                      >
                        <template v-if="leadDetail.name">{{
                          leadDetail.name
                        }}</template>
                        <template v-else
                          ><em class="text--secondary">No Name</em></template
                        >
                      </td>
                    </tr>
                    <tr>
                      <td width="200" class="pb-2 font-size-18 text-capitalize">
                        Customer Company
                      </td>
                      <td
                        class="pb-2 font-size-18 font-weight-600 text-capitalize"
                      >
                        <template v-if="leadDetail.company">{{
                          leadDetail.company
                        }}</template>
                        <template v-else
                          ><em class="text--secondary"
                            >No Company
                          </em></template
                        >
                      </td>
                    </tr>
                    <tr>
                      <td width="200" class="pb-2 font-size-18 text-capitalize">
                        Customer Email
                      </td>
                      <td
                        class="pb-2 font-size-18 font-weight-600 text-lowercase"
                      >
                        <template v-if="leadDetail.email">{{
                          leadDetail.email
                        }}</template>
                        <template v-else
                          ><em class="text--secondary">No Email </em></template
                        >
                      </td>
                    </tr>
                    <tr>
                      <td width="200" class="pb-2 font-size-18 text-capitalize">
                        Customer Phone No.
                      </td>
                      <td
                        class="pb-2 font-size-18 font-weight-600 text-capitalize"
                      >
                        <template v-if="leadDetail.phone_number">{{
                          leadDetail.phone_number
                        }}</template>
                        <template v-else
                          ><em class="text--secondary"
                            >No Phone Number</em
                          ></template
                        >
                      </td>
                    </tr>
                    <tr>
                      <td width="200" class="pb-2 font-size-18 text-capitalize">
                        Customer Website
                      </td>
                      <td
                        class="pb-2 font-size-18 font-weight-600 text-lowercase"
                      >
                        <template v-if="leadDetail.website">{{
                          leadDetail.website
                        }}</template>
                        <template v-else
                          ><em class="text--secondary">No Website</em></template
                        >
                      </td>
                    </tr>
                    <tr>
                      <td width="200" class="pb-2 font-size-18 text-capitalize">
                        Lead Date
                      </td>
                      <td
                        class="pb-2 font-size-18 font-weight-600 text-capitalize"
                      >
                        <template v-if="leadDetail.lead_date">{{
                          formatDate(leadDetail.lead_date)
                        }}</template>
                        <template v-else
                          ><em class="text--secondary"
                            >No Lead Date</em
                          ></template
                        >
                      </td>
                    </tr>
                    <tr>
                      <td width="200" class="pb-2 font-size-18 text-capitalize">
                        Last Contacted Date
                      </td>
                      <td
                        class="pb-2 font-size-18 font-weight-600 text-capitalize"
                      >
                        <template v-if="leadDetail.last_contact">{{
                          formatDate(leadDetail.last_contact)
                        }}</template>
                        <template v-else
                          ><em class="text--secondary"
                            >No Last Contacted Date</em
                          ></template
                        >
                      </td>
                    </tr>
                    <tr>
                      <td width="200" class="pb-2 font-size-18 text-capitalize">
                        Status
                      </td>
                      <td
                        class="pb-2 font-size-18 font-weight-600 text-capitalize"
                      >
                        <template v-if="leadDetail.status_text">{{
                          leadDetail.status_text
                        }}</template>
                        <template v-else
                          ><em class="text--secondary">No Status</em></template
                        >
                      </td>
                    </tr>
                    <tr>
                      <td width="200" class="pb-2 font-size-18 text-capitalize">
                        Source
                      </td>
                      <td
                        class="pb-2 font-size-18 font-weight-600 text-capitalize"
                      >
                        <template v-if="leadDetail.source">{{
                          leadDetail.source.text
                        }}</template>
                        <template v-else
                          ><em class="text--secondary">No Source</em></template
                        >
                      </td>
                    </tr>
                  </table>
                </v-col>
                <v-col md="6">
                  <table width="100%">
                    <tr>
                      <td width="200" class="pb-2 font-size-18 text-capitalize">
                        Lead #
                      </td>
                      <td
                        class="pb-2 font-size-18 font-weight-600 text-capitalize"
                      >
                        {{ leadDetail.barcode }}
                      </td>
                    </tr>
                    <tr>
                      <td width="200" class="pb-2 font-size-18 text-capitalize">
                        Reference #
                      </td>
                      <td
                        class="pb-2 font-size-18 font-weight-600 text-capitalize"
                      >
                        <template v-if="leadDetail.reference">{{
                          leadDetail.reference
                        }}</template>
                        <template v-else
                          ><em class="text--secondary"
                            >No Reference #</em
                          ></template
                        >
                      </td>
                    </tr>
                    <tr>
                      <td width="200" class="pb-2 font-size-18 text-capitalize">
                        Unit No.
                      </td>
                      <td
                        class="pb-2 font-size-18 font-weight-600 text-capitalize"
                      >
                        <template v-if="leadDetail.unit_no">{{
                          leadDetail.unit_no
                        }}</template>
                        <template v-else
                          ><em class="text--secondary"
                            >No Unit No.</em
                          ></template
                        >
                      </td>
                    </tr>
                    <tr>
                      <td width="200" class="pb-2 font-size-18 text-capitalize">
                        Address Line 1
                      </td>
                      <td
                        class="pb-2 font-size-18 font-weight-600 text-capitalize"
                      >
                        <template v-if="leadDetail.address_1">{{
                          leadDetail.address_1
                        }}</template>
                        <template v-else
                          ><em class="text--secondary"
                            >No Address Line 1</em
                          ></template
                        >
                      </td>
                    </tr>
                    <tr>
                      <td width="200" class="pb-2 font-size-18 text-capitalize">
                        Address Line 2
                      </td>
                      <td
                        class="pb-2 font-size-18 font-weight-600 text-capitalize"
                      >
                        <template v-if="leadDetail.address_2">{{
                          leadDetail.address_2
                        }}</template>
                        <template v-else
                          ><em class="text--secondary"
                            >No Address Line 2</em
                          ></template
                        >
                      </td>
                    </tr>
                    <tr>
                      <td width="200" class="pb-2 font-size-18 text-capitalize">
                        Postal Code
                      </td>
                      <td
                        class="pb-2 font-size-18 font-weight-600 text-capitalize"
                      >
                        <template v-if="leadDetail.zip">{{
                          leadDetail.zip
                        }}</template>
                        <template v-else
                          ><em class="text--secondary"
                            >No Postal Code</em
                          ></template
                        >
                      </td>
                    </tr>
                    <tr>
                      <td width="200" class="pb-2 font-size-18 text-capitalize">
                        Country
                      </td>
                      <td
                        class="pb-2 font-size-18 font-weight-600 text-capitalize"
                      >
                        <template v-if="leadDetail.country">{{
                          leadDetail.country
                        }}</template>
                        <template v-else
                          ><em class="text--secondary">No Country</em></template
                        >
                      </td>
                    </tr>
                    <tr>
                      <td width="200" class="pb-2 font-size-18 text-capitalize">
                        Industry
                      </td>
                      <td
                        class="pb-2 font-size-18 font-weight-600 text-capitalize"
                      >
                        <template v-if="leadDetail.industry">{{
                          leadDetail.industry.text
                        }}</template>
                        <template v-else
                          ><em class="text--secondary"
                            >No Industry</em
                          ></template
                        >
                      </td>
                    </tr>
                    <tr>
                      <td width="200" class="pb-2 font-size-18 text-capitalize">
                        Lead Value
                      </td>
                      <td
                        class="pb-2 font-size-18 font-weight-600 text-capitalize"
                      >
                        <template v-if="leadDetail.lead_value">{{
                          $accountingJS.formatMoney(leadDetail.lead_value)
                        }}</template>
                        <template v-else
                          ><em class="text--secondary"
                            >No Lead Value
                          </em></template
                        >
                      </td>
                    </tr>
                    <tr>
                      <td width="200" class="pb-2 font-size-18 text-capitalize">
                        Assigned
                      </td>
                      <td
                        class="pb-2 font-size-18 font-weight-600 text-capitalize"
                      >
                        <template v-if="leadDetail.assigned_user">{{
                          leadDetail.assigned_user.full_name
                        }}</template>
                        <template v-else
                          ><em class="text--secondary"
                            >No User Assigned</em
                          ></template
                        >
                      </td>
                    </tr>
                  </table>
                </v-col>
                <v-col
                  md="12"
                  class="py-0"
                  content="Description"
                  v-tippy="{ placement: 'top-start' }"
                >
                  <read-more
                    class="custom-read-more custom-border-grey-dashed font-weight-600 font-size-18 p-4 custom-gray-color"
                    more-str="read more"
                    :text="leadDetail.description || '<em>No Description</em>'"
                    link="#"
                    less-str="read less"
                    :max-chars="200"
                  >
                  </read-more>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
          <v-tab-item
            v-if="getPermission('attachment:view')"
            value="attachment"
          >
            <v-container fluid>
              <v-layout>
                <v-flex>
                  <v-btn
                    v-on:click="addAttachment"
                    class="my-2 mr-2 custom-bold-button white--text"
                    color="cyan"
                    small
                  >
                    Add Attachment
                  </v-btn>
                  <v-btn
                    v-if="attachmentActive"
                    class="my-2 ml-2 custom-bold-button white--text"
                    color="cyan"
                    small
                    v-on:click="saveAttachment"
                  >
                    Save Attachment
                  </v-btn>
                </v-flex>
              </v-layout>
              <v-row>
                <v-col md="12" v-if="attachmentActive">
                  <FileTemplate
                    allowUpload
                    isMinDisplay
                    v-on:file:updated="updateAttachment"
                  ></FileTemplate>
                </v-col>
                <v-col md="12" v-if="attachments.length > 0">
                  <FileTemplate
                    isMinDisplay
                    :allowDelete="false"
                    :attachments="attachments"
                  ></FileTemplate>
                </v-col>
                <v-col md="12" v-else>
                  <template v-if="!pageLoading">
                    <p
                      class="m-0 text--secondary row-not-found text-center font-weight-500 font-size-16"
                    >
                      <img
                        width="30"
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image mx-2"
                      />
                      Uhh... There are no attachment for this lead at the
                      moment.
                    </p>
                  </template>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
          <v-tab-item v-if="getPermission('proposal:view')" value="proposal">
            <Proposal :detail="leadDetail" type="lead"></Proposal>
          </v-tab-item>
          <v-tab-item v-if="getPermission('task:view')" value="task">
            <Tasks :detail="leadDetail" type="lead"></Tasks>
          </v-tab-item>
          <v-tab-item value="comment">
            <v-container fluid>
              <v-layout>
                <v-flex>
                  <v-btn
                    v-on:click="addNote"
                    class="my-2 mr-2 custom-bold-button white--text"
                    color="cyan"
                    small
                    :disabled="commentLoading"
                  >
                    <template v-if="commentActive">Close</template>
                    <template v-else>Add Note</template>
                  </v-btn>
                  <v-btn
                    v-if="commentActive"
                    class="my-2 ml-2 custom-bold-button white--text"
                    color="cyan"
                    small
                    :disabled="commentLoading"
                    v-on:click="saveNote"
                  >
                    Save Note
                  </v-btn>
                </v-flex>
              </v-layout>
              <v-row>
                <template v-if="commentActive">
                  <v-col md="12" class="px-8">
                    <editor v-model="commentCreate.description" />
                  </v-col>
                  <v-col md="12">
                    <FileTemplate
                      allowUpload
                      isMinDisplay
                      v-on:file:updated="updateNoteAttachment"
                    ></FileTemplate>
                  </v-col>
                </template>
                <v-col md="12" v-if="comments.length > 0">
                  <v-timeline dense clipped>
                    <v-timeline-item
                      color="white"
                      v-for="(row, index) in comments"
                      :key="index"
                      large
                    >
                      <template v-slot:icon>
                        <v-avatar>
                          <v-img
                            :src="row.profile_logo"
                            :lazy-src="$defaultProfileImage"
                          ></v-img>
                        </v-avatar>
                      </template>
                      <v-row class="pt-1">
                        <v-col md="3">
                          <em
                            v-tippy="{ arrow: true }"
                            content="Created At"
                            class="font-weight-600"
                            >{{ formatDateTime(row.added_at) }}</em
                          ><br />
                          <em
                            v-tippy="{ arrow: true }"
                            content="Created By"
                            class="font-weight-600 text-capitalize"
                            >{{ row.full_name }}</em
                          >
                        </v-col>
                        <v-col md="9">
                          <div class="task-comment-delete">
                            <v-btn
                              icon
                              :disabled="isPageLoading"
                              v-on:click="deleteNote(row.id)"
                            >
                              <v-icon color="red lighten-1">mdi-delete</v-icon>
                            </v-btn>
                          </div>
                          <div class="caption mb-2">
                            <read-more
                              class="custom-read-more font-weight-600 font-size-16 pb-1 pr-2"
                              more-str="read more"
                              :text="row.description || ''"
                              link="#"
                              less-str="read less"
                              :max-chars="60"
                            >
                            </read-more>
                          </div>
                          <FileTemplate
                            isMinDisplay
                            :allowDelete="false"
                            :attachments="row.attachments"
                          ></FileTemplate>
                        </v-col>
                      </v-row>
                    </v-timeline-item>
                  </v-timeline>
                </v-col>
                <v-col md="12" v-else>
                  <template v-if="!pageLoading">
                    <p
                      class="m-0 text--secondary row-not-found text-center font-weight-500 font-size-16"
                    >
                      <img
                        width="30"
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image mx-2"
                      />
                      Uhh... There are no comment for this lead at the moment.
                    </p>
                  </template>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
          <v-tab-item v-if="getPermission('history:view')" value="history">
            <InternalHistoryDetail
              type="lead"
              :type_id="leadDetail.id"
            ></InternalHistoryDetail>
          </v-tab-item>
        </v-tabs-items>
      </perfect-scrollbar>
      <LeadDelete
        :deleteDialog="leadDeleteDialog"
        :requestUrl="leadDeleteRequestUrl"
        v-on:delete:success="leadDeleteSuccess"
        v-on:delete:close="leadDeleteDialog = false"
      ></LeadDelete>
    </template>
  </DetailTemplate>
</template>
<script>
import moment from "moment-timezone";
import CommonMixin from "@/core/plugins/common-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET, PATCH, POST, DELETE } from "@/core/services/store/request.module";
import DetailTemplate from "@/view/pages/partials/Detail-Template.vue";
import CustomStatus from "@/view/pages/partials/CustomStatus";
import FileTemplate from "@/view/pages/partials/FileTemplate";
import LeadDelete from "@/view/pages/partials/Delete-Request-Template.vue";
import Proposal from "@/view/pages/partials/Detail/Proposal.vue";
import Tasks from "@/view/pages/partials/Detail/Tasks";
import InternalHistoryDetail from "@/view/pages/partials/Detail/Internal-History-Detail.vue";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin],
  name: "lead-detail",
  title: "Detail Lead",
  data() {
    return {
      lead: null,
      leadDeleteDialog: false,
      moreActions: new Array(),
      attachments: new Array(),
      comments: new Array(),
      pageLoading: false,
      attachmentLoading: false,
      attachmentActive: false,
      commentLoading: false,
      commentActive: false,
      commentCreate: new Object({
        description: null,
        attachments: [],
      }),
      attachmentCreate: new Object({
        attachments: [],
      }),
      leadDetail: new Object({
        id: null,
        title: null,
        name: null,
        company: null,
        email: null,
        phone_number: null,
        website: null,
        lead_date: null,
        last_contact: null,
        status: null,
        status_text: null,
        source: null,
        barcode: null,
        reference: null,
        unit_no: null,
        address_1: null,
        address_2: null,
        zip: null,
        country: null,
        industry: null,
        lead_value: null,
        assigned_user: null,
        description: null,
        attachments: new Array(),
        city: null,
        state: null,
      }),
      tabs: [
        {
          title: "Overview",
          icon: "mdi-phone",
          key: "lead",
          disabled: false,
        },
        /* {
          title: "Proposals",
          icon: "mdi-account-multiple",
          key: "proposal",
          disabled: false,
        }, */
        {
          title: "Notes",
          icon: "mdi-account-multiple",
          key: "comment",
          disabled: false,
        },
        {
          title: "Attachments",
          icon: "mdi-account-multiple",
          key: "attachment",
          disabled: false,
        },
        /* {
          title: "Tasks",
          icon: "mdi-account-multiple",
          key: "task",
          disabled: false,
        }, */
        {
          title: "History",
          icon: "mdi-credit-card",
          key: "history",
          disabled: false,
        },
      ],
    };
  },
  components: {
    DetailTemplate,
    CustomStatus,
    Proposal,
    Tasks,
    InternalHistoryDetail,
    LeadDelete,
    FileTemplate,
    editor: TinyMCE,
  },
  methods: {
    getAttachments() {
      const _this = this;
      _this.$store
        .dispatch(GET, {
          url: "leads/" + _this.lead + "/attachments",
        })
        .then(({ data }) => {
          _this.attachments = data;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    addAttachment() {
      this.attachmentActive = !this.attachmentActive;
      this.attachmentCreate = new Object({
        attachments: [],
      });
    },
    updateAttachment(param) {
      this.attachmentCreate.attachments = param;
    },
    saveAttachment() {
      const _this = this;
      _this.attachmentLoading = true;
      _this.$store
        .dispatch(POST, {
          url: "leads/" + _this.lead + "/attachments",
          data: _this.attachmentCreate,
        })
        .then(() => {
          _this.attachmentCreate = new Object({
            attachments: [],
          });
          _this.attachmentActive = false;
          _this.getAttachments();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.attachmentLoading = false;
        });
    },
    getNotes() {
      const _this = this;
      _this.commentLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "leads/" + _this.lead + "/comments",
        })
        .then(({ data }) => {
          _this.comments = data;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.commentLoading = false;
        });
    },
    addNote() {
      this.commentActive = !this.commentActive;
      this.commentCreate = new Object({
        description: null,
        attachments: [],
      });
    },
    updateNoteAttachment(param) {
      this.commentCreate.attachments = param;
    },
    saveNote() {
      const _this = this;
      _this.commentLoading = true;
      _this.$store
        .dispatch(POST, {
          url: "leads/" + _this.lead + "/comments",
          data: _this.commentCreate,
        })
        .then(() => {
          _this.commentCreate = new Object({
            description: null,
            attachments: [],
          });
          _this.commentActive = false;
          _this.getNotes();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.commentLoading = false;
        });
    },
    deleteNote(itemid) {
      const _this = this;
      _this.commentLoading = true;
      _this.$store
        .dispatch(DELETE, {
          url: "leads/" + _this.lead + "/comments/" + itemid,
        })
        .then(() => {
          _this.getNotes();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.commentLoading = false;
        });
    },
    leadDeleteSuccess() {
      this.leadDeleteDialog = false;
      this.goBack();
    },
    updateMoreAction(action) {
      const _this = this;
      switch (action) {
        case "edit":
          _this.$router.push(
            _this.getDefaultRoute("lead.update", {
              params: {
                id: _this.lead,
              },
            })
          );
          break;
        case "convert_to_customer":
          _this.$router.push(
            _this.getDefaultRoute("customer.create", {
              query: {
                lead: _this.lead,
              },
            })
          );
          break;
        case "convert_to_proposal":
          _this.$router.push(
            _this.getDefaultRoute("proposal.create", {
              query: {
                lead: _this.lead,
              },
            })
          );
          break;
        case "delete":
          _this.leadDeleteDialog = true;
          break;
        case "mark_as_attempted_to_contact":
          _this.updateLead({ status: 1 });
          break;
        case "mark_as_contact_in_future":
          _this.updateLead({ status: 2 });
          break;
        case "mark_as_contacted":
          _this.updateLead({ status: 3 });
          break;
        case "mark_as_junk_lead":
          _this.updateLead({ status: 4 });
          break;
        case "mark_as_lost_lead":
          _this.updateLead({ status: 5 });
          break;
        case "mark_as_not_contacted":
          _this.updateLead({ status: 6 });
          break;
        case "mark_as_pre_qualified":
          _this.updateLead({ status: 7 });
          break;
        case "mark_as_not_qualified":
          _this.updateLead({ status: 8 });
          break;
      }
    },
    updateLead(data) {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(PATCH, {
          url: "leads/" + _this.lead,
          data: data,
        })
        .then(() => {
          _this.getLead();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    getLead() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "leads/" + _this.lead,
        })
        .then(({ data }) => {
          _this.leadDetail = new Object({
            id: data.id || null,
            title: data.title || null,
            name: data.name || null,
            company: data.company || null,
            email: data.email || null,
            phone_number: data.phone_number || null,
            website: data.website || null,
            lead_date: data.lead_date || null,
            last_contact: data.last_contact || null,
            status: data.status || null,
            status_text: data.status_text || null,
            source: data.source || null,
            barcode: data.barcode || null,
            reference: data.reference || null,
            unit_no: data.unit_no || null,
            address_1: data.address_1 || null,
            address_2: data.address_2 || null,
            zip: data.zip || null,
            country: data.country || null,
            industry: data.industry || null,
            lead_value: data.lead_value || null,
            assigned_user: data.assigned_user || null,
            description: data.description || null,
            attachments: new Array(),
            city: data.city || null,
            state: data.state || null,
          });
          _this.attachments = data.attachments;
          _this.moreActions = data.more_actions;
        })
        .catch((error) => {
          _this.logError(error);
          _this.goBack();
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  created() {
    const _this = this;
    _this.lead = _this.lodash.toSafeInteger(_this.$route.params.id);
    if (_this.lead <= 0) {
      _this.goBack();
    }
  },
  mounted() {
    const _this = this;
    _this.getNotes();
    _this.getAttachments();
    _this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Lead", route: "lead" },
      { title: "Detail" },
    ]);

    _this.getLead();

    if (_this.$route.query && _this.$route.query.tab) {
      _this.leadTab = _this.$route.query.tab;
    }
  },
  computed: {
    leadDeleteRequestUrl() {
      return "leads/" + this.lead;
    },
    leadTitle() {
      return this.leadDetail.title;
    },
    leadTab: {
      set(val) {
        let query = { ...this.$route.query };
        query.tab = val;
        if (val != this.leadTab) {
          this.$router.push({ query });
        }
      },
      get() {
        return this.$route.query.tab || "customer";
      },
    },
  },
};
</script>
