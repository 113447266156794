<template>
  <Dialog :commonDialog="cDialog" :dialogWidth="dialogWidth">
    <template v-slot:title>
      <v-layout>
        <v-flex> Update follow Up </v-flex>
      </v-layout>
    </template>
    <template v-slot:body>
      <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll custom-box-top-inner-shadow"
        style="max-height: 90vh; position: relative"
      >
        <v-container class="py-0">
          <v-row class="py-0">
            <v-col md="12">
              <v-radio-group
                v-model="data.follow_up"
                mandatory
                row
                hide-details
                class="ml-3 my-3"
              >
                <v-radio
                  label="Follow Up"
                  value="follow-up"
                  color="cyan"
                  class="mr-10"
                ></v-radio>
                <v-radio
                  label="Mark As Closed"
                  value="mark-as-closed"
                  color="cyan"
                  class="mr-10"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <template v-if="data.follow_up == 'follow-up'">
              <v-col md="6">
                <label class="font-weight-700 font-size-16 pl-2">Remark</label>
                <v-textarea
                  v-model.trim="data.follow_up_remarks"
                  auto-grow
                  dense
                  filled
                  flat
                  label="Remark"
                  solo
                  color="cyan"
                  hide-details
                  row-height="20"
                ></v-textarea>
              </v-col>

              <v-col md="6">
                <div class="py-1 font-weight-600">Reminder Date</div>
                <Datepicker
                  v-model="data.follow_up_date"
                  solo
                  :min-date="todayDate"
                >
                </Datepicker>
              </v-col>
            </template>
            <template v-if="data.follow_up == 'mark-as-closed'">
              <v-col md="12">
                <label class="font-weight-700 font-size-16 pl-2">Remark</label>
                <v-textarea
                  v-model.trim="data.follow_up_remarks"
                  auto-grow
                  dense
                  filled
                  flat
                  label="Remark"
                  solo
                  color="cyan"
                  hide-details
                  row-height="20"
                ></v-textarea>
              </v-col>
            </template>
          </v-row>
        </v-container>
      </perfect-scrollbar>
    </template>
    <template v-slot:action>
      <div class="text-right custom-border-top py-4 mt-4">
        <v-btn
          class="mx-2 custom-bold-button white--text"
          color="cyan"
          :disabled="pageLoading"
          :loading="pageLoading"
          v-on:click="createFollowup"
        >
          Save
        </v-btn>
        <!-- <v-btn
          class="mx-2 custom-bold-button white--text"
          color="cyan"
          :disabled="pageLoading"
          :loading="pageLoading"
          v-on:click="createFollowup('closed')"
        >
          Mark As Closed
        </v-btn> -->
        <v-btn
          :disabled="formLoading"
          class="custom-grey-border custom-bold-button"
          v-on:click="$emit('close-dialog', true)"
        >
          Cancel
        </v-btn>
      </div>
    </template>
  </Dialog>
</template>
<script>
import Dialog from "@/view/pages/partials/Dialog.vue";
import { PUT, GET } from "@/core/services/store/request.module";
import CommonMixin from "@/core/plugins/common-mixin";
//import CertificateFileTemplate from "@/view/pages/partials/CertificateFileTemplate.vue";
import { ServiceFormEventBus } from "@/core/lib/service.form.lib";
import Datepicker from "@/view/pages/partials/Datepicker.vue";
export default {
  mixins: [CommonMixin],
  props: {
    cDialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    e_designation: {
      type: Array,
      default: () => {
        return new Array();
      },
    },

    /* visitAction: {
      required: true,
      type: Boolean,
      default: false,
    }, */
    detail: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    visit: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
  },
  watch: {
    dialog() {
      this.init();
    },
    e_designation: {
      deep: true,
      immediate: true,
      handler(param) {
        this.user_designation = param;
      },
    },
  },
  data: () => {
    return {
      visitNoteActive: true,
      todayDate: null,
      data: {
        follow_up: null,
        follow_up_remarks: null,
        follow_up_date: null,
      },
      pageLoading: false,
      attachments: [],
      user_designation: [
        {
          title: null,
        },
      ],
    };
  },
  methods: {
    init() {
      this.attachments = [];
    },
    /*  updateFiles(param) {
      this.visitNoteDocument = param;
    }, */

    createFollowup() {
      const _this = this;
      /*  if (!_this.$refs.visitNoteForm.validate()) {
        return false;
      } */
      _this.pageLoading = true;

      _this.$store
        .dispatch(PUT, {
          url:
            "job/" +
            _this.detail.id +
            "/visit/" +
            _this.visit.id +
            "/followup/" +
            _this.visit.followup[0].id,
          data: {
            action: _this.data.follow_up,
            remark: _this.data.follow_up_remarks,
            date: _this.data.follow_up_date,
          },
        })
        .then(() => {
          ServiceFormEventBus.$emit("reload:cetificate-data");
          _this.$emit("close-dialog", true);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },

    getVisit(id) {
      const _this = this;
      let requestUrl = "job/" + _this.detail.id + "/visit/" + id;
      _this.$store
        .dispatch(GET, { url: requestUrl })
        .then(({ data }) => {
          _this.visitDetail = data;
          // _this.visitNotes = data.notes;
          _this.visitDetailDialog = true;
          _this.visitDeleteRequestURL = requestUrl;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    updateFiles(param) {
      this.visitNoteDocument = param;
    },

    closeNote() {
      this.formValid = true;
      this.visitNote = null;
      this.visitNoteDocument = new Array();
      this.visitNoteActive = false;
    },

    /* updateContractSetting() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(PATCH, {
          url: "setting/user-designation",
          data: { user_designation: _this.user_designation },
        })
        .then(() => {
          _this.$emit("close-dialog", true);
          _this.$emit("get-e-designation", true);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    }, */
    /* createContractType() {
      this.user_designation.push({
        value: null,
      });
    }, */
    deleteContractType(index) {
      if (this.user_designation.length > 0) {
        this.user_designation.splice(index, 1);
      }
    },
  },
  components: {
    Dialog,
    Datepicker,
  },

  created() {
    this.todayDate = new Date().toISOString();
    // console.log({todayDate: this.todayDate})
  },

  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 50);
    },
  },
};
</script>
