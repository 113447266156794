import { POST, QUERY } from "@/core/services/store/request.module";
import LocalService from "@/core/services/local.service";
import { SearchEventBus } from "@/core/lib/search.lib";
import { PaginationEventBus } from "@/core/lib/pagination.lib";
import { saveAs } from "file-saver";
import { getConfig } from "@/core/services/local.service";

const validQueryParams = [
  "status",
  "sort_column",
  "sort_order",
  "limit",
  "offset",
];

export default {
  data() {
    return {
      searchParam: null,
      listingSearch: {},
      totalRows: 0,
      rowsOffset: 0,
      //teamId:0,
      dataLoading: true,
      searchEnabled: false,
      topExtraList: [],
      categoryList: [],
      rowData: [],
      defaultColShow: [],
      defaultColDefs: [],
      queryParams: {},
      sortData: {},
      entity: {},
      entityList: [],
      currentPage: 1,
      totalPages: 0,
      totalSelected: 0,
      paginationPageSize: 0,
      selectedRows: [],
      searchDialog: false,
      alphabet: null,
      pageModule: null,
      category: null,
      routeAPI: null,
      routeName: null,
      routeDetailName: null,
      searchableArray: [],
      entityObject: {},
    };
  },
  watch: {
    selectedRows() {
      this.rowSelected();
    },
    status(params) {
      console.log(params, "params");
      this.currentPage = 1;
      if (params) {
        this.lodash.assign(this.queryParams, {
          status: params,
        });
        this.pushQueryParams(52);
      }
    },
    defaultColShow() {
      this.updateVisible();
    },
    defaultColDefs() {
      this.updateColState();
    },
    sortData(params) {
      this.lodash.assign(this.queryParams, params);
      this.pushQueryParams(63);
    },
    $route() {
      this.getRows();
    },
  },
  methods: {
    getLineItem(alphabet) {
      this.alphabet = alphabet;
      this.getRows();
    },
    columnLength() {
      const defaultColDefs = this.lodash.filter(this.defaultColDefs, {
        visible: true,
      });
      if (defaultColDefs) {
        return defaultColDefs.length;
      }
      return 0;
    },
    updateEntity(row) {
      this.entity = row;
      this.entityObject = row;
      this.$nextTick(() => {
        this.getRows();
      });
    },
    exportCurrentView(type) {
      const _this = this;
      let defaultColDefs = _this.lodash.cloneDeep(_this.defaultColDefs);
      let columns = [];
      for (let i = 0; i < defaultColDefs.length; i++) {
        if (defaultColDefs[i].visible && defaultColDefs[i].field != "id") {
          columns.push({
            field: defaultColDefs[i].field,
            header: defaultColDefs[i].headerName,
          });
        }
      }
      let timeStamp = new Date();
      let requestParams = new Object({ columns });
      _this.lodash.assign(requestParams, _this.$route.query);
      if (!requestParams.status) {
        requestParams.status = "all";
      }
      _this.$store
        .dispatch(POST, {
          url: "export-current-view/" + type,
          data: requestParams,
        })
        .then((response) => {
          let blob = new Blob([response], {
            type: "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf8",
          });
          saveAs(
            blob,
            type + "-export-current-view-" + timeStamp.getTime() + ".xls"
          );
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    getFilterLabel(field, page_module) {
      let child = [];
      let levelOne = field.split(".");
      let parent = levelOne[0].split("_").join(" ");
      if (levelOne[1]) {
        child.push(levelOne[1].split("_").join(" "));
      }
      if (child.length) {
        let childText = child.join(" ");
        return parent + "[" + childText + "]";
      }
      return this.lodash
        .replace(parent, "text", "name")
        .replaceAll("[barcode]", " #")
        .replaceAll("barcode", `${page_module} #`);
    },
    filterRows() {
      this.listingSearch = this.lodash.pickBy(
        this.listingSearch,
        this.lodash.identity
      );
      if (this.lodash.isEmpty(this.listingSearch) === false) {
        this.removeUnnecessaryQueryParam();
        this.lodash.assign(this.queryParams, this.listingSearch);
        this.pushQueryParams(128);
        this.searchParam = null;
        this.searchDialog = false;
        this.searchEnabled = true;
      }
    },
    clearSearch() {
      this.searchParam = null;
      this.searchDialog = false;
      this.searchEnabled = false;
      this.removeUnnecessaryQueryParam();
      this.pushQueryParams(139);
      this.listingSearch = new Object();
    },
    pushQueryParams() {
      const _this = this;
      if (
        !_this.lodash.isEqual(
          _this.$route.query,
          _this.lodash.omitBy(_this.queryParams, _this.lodash.isNil)
        )
      ) {
        if (this.teamId) {
          _this.getRows(150);
        } else {
          _this.$router
            .replace(
              _this.getDefaultRoute(_this.routeName, {
                query: _this.queryParams,
              })
            )
            .then(() => {
              _this.getRows(150);
            })
            .catch((error) => {
              _this.logError(error);
            });
        }
      } else {
        _this.getRows(156);
      }
    },
    getRows() {
      const _this = this;
      let requestParams = {
        current_page: _this.currentPage || 1,
        per_page: _this.paginationPageSize,
        category: _this.category,
        team: _this.teamId,
        entity: _this.entity ? _this.entity.id : undefined,
        alphabet: _this.alphabet ? _this.alphabet : null,
      };
      _this.lodash.assign(requestParams, _this.$route.query);
      /* if(requestParams.team){

      } */
      if (!requestParams.status) {
        requestParams.status = "all";
      } else {
        _this.status = requestParams.status;
      }

      if (this.routeAPI == "contract" && this.expiredFilter) {
        if (this.expiredFilter.value > 0) {
          requestParams.expired_type = this.expiredFilter.type;
          requestParams.expired_value = this.expiredFilter.value;
        }
      }

      _this.dataLoading = true;
      _this.totalPages = 0;
      _this.currentPage = 0;
      _this.rowData = new Array();
      _this.totalRows = 0;
      _this.rowsOffset = 0;
      _this.$store
        .dispatch(QUERY, {
          url: this.routeAPI,
          data: requestParams,
        })
        .then(({ data }) => {
          if (_this.pageModule === "product-listing") {
            _this.topExtraList = [
              {
                title: "Low Stock",
                status_key: "low_stock",
                status_value: 1,
                value: data.low_stock,
              },
            ];
          }
          _this.totalPages = Math.ceil(data.total_rows / data.per_page);
          _this.currentPage = data.current_page;
          _this.rowData = data.rows;
          _this.totalRows = data.total_rows;
          _this.rowsOffset = data.offset;
          _this.statusList = data.status_list;
          if (data.category_list) {
            _this.categoryList = data.category_list;
          }
          _this.searchableArray = data.searchable;
          if (
            _this.searchableArray &&
            _this.lodash.isArray(_this.searchableArray)
          ) {
            _this.searchableArray.push("dates");
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          if (!_this.$route.query.search) {
            _this.isSearchEnabled();
          }
          _this.selectedRows = [];
          _this.dataLoading = false;
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        });
    },
    rowSelected() {
      this.totalSelected = this.selectedRows.length;
    },
    updateVisible() {
      let columnState = LocalService.getData(`${this.pageModule}-column-state`);
      if (columnState && columnState.length > 0) {
        for (let i = columnState.length - 1; i >= 0; i--) {
          let index = this.defaultColShow.indexOf(columnState[i].field);
          if (Number.isInteger(index) && this.defaultColShow[index]) {
            columnState[i].visible = true;
          } else {
            columnState[i].visible = false;
          }
          if (i === 0) {
            this.defaultColDefs = columnState;
            LocalService.saveData(
              `${this.pageModule}-column-state`,
              columnState
            );
            LocalService.saveData(
              `${this.pageModule}-column-visible`,
              this.defaultColShow
            );
          }
        }
      } else {
        this.updateColState();
      }
    },
    updateSorting(sortObject) {
      let columnState = LocalService.getData(`${this.pageModule}-column-state`);
      if (columnState && columnState.length > 0) {
        for (let i = columnState.length - 1; i >= 0; i--) {
          if (
            columnState[i] &&
            columnState[i].field &&
            columnState[i].field == sortObject.field
          ) {
            if (columnState[i].sort === "asc") {
              columnState[i].sort = "desc";
            } else if (columnState[i].sort === "desc") {
              columnState[i].sort = null;
            } else {
              columnState[i].sort = "asc";
            }
            this.updateMoreActions(sortObject, columnState[i].sort);
          } else {
            columnState[i].sort = null;
          }
          if (i === 0) {
            LocalService.saveData(
              `${this.pageModule}-column-state`,
              columnState
            );
            this.applyColState();
          }
        }
      } else {
        this.updateColState();
      }
    },
    applyColState() {
      let columnState = LocalService.getData(`${this.pageModule}-column-state`);
      if (columnState && columnState.length > 0) {
        this.defaultColDefs = columnState;
      }
      let columnVisible = LocalService.getData(
        `${this.pageModule}-column-visible`
      );
      if (columnVisible && columnVisible.length > 0) {
        this.defaultColShow = columnVisible;
      }
      let moreActionState = LocalService.getData(
        `${this.pageModule}-more-actions`
      );
      if (moreActionState && moreActionState.length > 0) {
        this.moreActions = moreActionState;
      }
      this.sortData = this.sortColumns(columnState);
    },
    updateColState() {
      LocalService.saveData(
        `${this.pageModule}-more-actions`,
        this.moreActions
      );
      LocalService.saveData(
        `${this.pageModule}-column-state`,
        this.defaultColDefs
      );
      LocalService.saveData(
        `${this.pageModule}-column-visible`,
        this.defaultColShow
      );
    },
    closeSearchDialog() {
      this.searchDialog = false;
    },
    submitSearchDialog(params) {
      this.lodash.assign(this.queryParams, params);
      this.pushQueryParams(312);
      this.closeSearchDialog();
    },
    clearSelections() {
      this.selectedRows = [];
    },
    rowClicked(params) {
      this.$router.push(
        this.getDefaultRoute(this.routeDetailName, {
          params: { id: params.id },
        })
      );
    },
    updateMoreActions(sortObject, sortType) {
      let moreActionState = LocalService.getData(
        `${this.pageModule}-more-actions`
      );
      if (moreActionState && moreActionState.length > 0) {
        for (let i = moreActionState.length - 1; i >= 0; i--) {
          if (
            moreActionState[i] &&
            moreActionState[i].action &&
            moreActionState[i].action == sortObject.field
          ) {
            moreActionState[i].sort = sortType;
          } else {
            moreActionState[i].sort = null;
          }
          if (i === 0) {
            this.moreActions = moreActionState;
            LocalService.saveData(
              `${this.pageModule}-more-actions`,
              this.moreActions
            );
          }
        }
      }
    },
    sortColumns(columnState) {
      let result = {
        sort_column: undefined,
        sort_order: undefined,
      };
      if (columnState && columnState.length) {
        for (let i = columnState.length - 1; i >= 0; i--) {
          if (columnState[i] && columnState[i].field && columnState[i].sort) {
            result["sort_column"] = columnState[i].field;
            result["sort_order"] = columnState[i].sort;
          }
        }
      }
      return result;
    },
    printRowData(col, row) {
      let result = row[col.field];
      const _this = this;
      if (col.cellRenderer && (result || result === false)) {
        switch (col.cellRenderer) {
          case "formatDate":
            result = _this.formatDate(result);
            break;
          case "formatDateTime":
            result = _this.formatDateTime(result);
            break;
          case "booleanToString":
            result = _this.booleanToString(result);
            break;
          case "toUpper":
            result = _this.lodash.toUpper(result);
            break;
          case "toLower":
            result = _this.lodash.toLower(result);
            break;
          case "customerInfo":
            result = `<div class="customer-info"><b>Name: </b>${row.display_name}<br><b>Company: </b>${row.company_name}<br><b>Email: </b>${row.primary_email}<br><b>Phone: </b>${row.primary_phone}<br></div>`;
            break;
          case "formattedCompanyInfo": {
            let tempArr = [];
            if (row.customer_display_name) {
              tempArr.push(row.customer_display_name);
            }
            if (row.default_person) {
              tempArr.push(row.default_person);
            }
            result = tempArr.join("<br>");
            break;
          }
          case "formattedPropertyInfo": {
            let tempArr = [];
            if (row.unit_no) {
              tempArr.push(row.unit_no);
            }
            if (row.street_1) {
              tempArr.push(row.street_1);
            }
            if (row.street_2) {
              tempArr.push(row.street_2);
            }
            if (row.zip_code) {
              tempArr.push(row.zip_code);
            }
            if (row.city) {
              tempArr.push(row.city);
            }
            if (row.state) {
              tempArr.push(row.state);
            }
            if (row.country) {
              tempArr.push(row.country);
            }
            result = tempArr.join(", ");
            break;
          }
          case "formattedDateTime":
            if (col.child && row[col.field]) {
              result = `<span title="${_this.formatDateTime(result)}">${
                row[col.child]
              }</span>`;
            }
            break;
          case "getCellVariant": {
            let index = _this.lodash.findIndex(result, function (attr) {
              return attr.variant.toString() === col.variant.toString();
            });
            if (
              Number.isInteger(index) &&
              parseInt(index) >= 0 &&
              _this.lodash.isEmpty(result[index]) === false
            ) {
              result = result[index].value;
            } else {
              result = null;
            }
            break;
          }
        }
      }
      return result;
    },
    removeUnnecessaryQueryParam() {
      const _this = this;
      for (let key in _this.queryParams) {
        if (!_this.lodash.includes(validQueryParams, key)) {
          delete _this.queryParams[key];
        }
      }
    },
    isSearchEnabled() {
      const _this = this;
      _this.searchEnabled = false;
      if (_this.lodash.isEmpty(_this.$route.query) === false) {
        for (let key in _this.$route.query) {
          if (_this.lodash.includes(_this.searchableArray, key)) {
            _this.searchEnabled = true;
            _this.listingSearch[key] = _this.$route.query[key];
          }
        }
      }

      if (
        !_this.lodash.isEmpty(_this.listingSearch["dates"]) &&
        _this.lodash.isArray(_this.listingSearch["dates"])
      ) {
        _this.dates = _this.listingSearch["dates"];
      }
    },
  },
  mounted() {
    const _this = this;

    _this.entityList = getConfig("entity");

    _this.entity = _this.lodash.head(_this.entityList);

    _this.entityObject = _this.lodash.head(_this.entityList);

    if (_this.lodash.isEmpty(_this.$route.query)) {
      _this.lodash.assign(_this.queryParams, {
        status: "all",
      });
      _this.pushQueryParams(579);
    }

    _this.isSearchEnabled();

    SearchEventBus.$on("clear-search", () => {
      _this.clearSearch();
    });

    SearchEventBus.$on("advanced-search", () => {
      _this.searchDialog = true;
    });

    PaginationEventBus.$on("update:pagination", (param) => {
      _this.currentPage = param;
      _this.getRows();
    });

    SearchEventBus.$on("start-search", (argument) => {
      _this.searchEnabled = false;
      _this.listingSearch = new Object();
      _this.searchParam = argument;
      if (_this.searchParam) {
        _this.searchEnabled = true;
      }
      _this.removeUnnecessaryQueryParam();
      _this.lodash.assign(_this.queryParams, {
        search: argument,
      });
      _this.pushQueryParams(608);
    });

    SearchEventBus.$emit("search-template", true);
    SearchEventBus.$emit("search-module-title", _this.routeName);
  },
  beforeDestroy() {
    this.searchEnabled = false;
    SearchEventBus.$emit("search-template", false);
    SearchEventBus.$off("clear-search");
    SearchEventBus.$off("start-search");
    SearchEventBus.$off("advanced-search");
    PaginationEventBus.$off("update:pagination");
  },
  computed: {
    showingFrom() {
      return this.lodash.isEmpty(this.rowData) === false
        ? this.rowsOffset + 1
        : 0;
    },
    showingTo() {
      return this.rowsOffset + (this.rowData ? this.rowData.length : 0);
    },
    selectedAll: {
      set(param) {
        this.selectedRows = [];
        if (param && this.rowData.length) {
          for (let i = this.rowData.length - 1; i >= 0; i--) {
            this.selectedRows.push(this.rowData[i].id);
          }
        }
      },
      get() {
        let totalItems = this.rowData.length;
        return totalItems > 0 ? this.selectedRows.length === totalItems : false;
      },
    },
  },
};
