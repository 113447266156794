<template>
  <v-container fluid class="custom-bthrust-dashboard pt-0">
    <v-row>
      <v-col md="12">
        <v-layout class="dashboard-top-button">
          <v-flex>
            <router-link
              :to="getDefaultRoute('customer.create')"
              class="btn btn-danger btn-shadow-hover font-weight-bolder w-100 font-size-18 dasboard-top-btn-font"
              >Create Customer</router-link
            >
          </v-flex>
          <!-- <v-flex class="mx-4">
            <router-link
              to=""
              @click.native="createTransaction('quotation')"
              class="btn btn-danger btn-shadow-hover font-weight-bolder w-100 font-size-18 dasboard-top-btn-font"
              >Create Quotation</router-link
            >
          </v-flex> -->
          <v-flex>
            <router-link
              to=""
              @click.native="createTransaction('job')"
              class="btn btn-danger btn-shadow-hover font-weight-bolder w-100 font-size-18 dasboard-top-btn-font"
              >Create Visit</router-link
            >
          </v-flex>
          <v-flex v-if="false" class="mx-4">
            <router-link
              to=""
              @click.native="createTransaction('invoice')"
              class="btn btn-danger btn-shadow-hover font-weight-bolder w-100 font-size-18 dasboard-top-btn-font"
              >Create Invoice</router-link
            >
          </v-flex>
          <v-flex>
            <router-link
              :to="getDefaultRoute('engineer.create')"
              class="btn btn-danger btn-shadow-hover font-weight-bolder w-100 font-size-18 dasboard-top-btn-font"
              >Create Technicians</router-link
            >
          </v-flex>
          <v-flex class="ml-4" v-if="false">
            <router-link
              :to="getDefaultRoute('profile.setting')"
              class="btn btn-danger btn-shadow-hover font-weight-bolder w-100 font-size-18 dasboard-top-btn-font"
              >Settings</router-link
            >
          </v-flex>
        </v-layout>
      </v-col>
      <v-col md="4">
        <!--begin::Mixed Widget 1-->
        <div class="card card-custom bg-gray-100 card-stretch gutter-b">
          <!--begin::Header-->
          <div class="card-header border-0 bg-danger py-5 pb-2">
            <h3 class="card-title font-weight-bolder text-white">
              Visit Status
            </h3>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body p-0 position-relative overflow-hidden">
            <!--begin::Chart-->
            <div
              id="kt_mixed_widget_1_chart"
              class="card-rounded-bottom bg-danger dashboard-total-jobs"
              style="min-height: 100px"
            ></div>
            <div class="card-spacer smallbxc_row">
              <!--begin::Row-->
              <div class="row m-0">
                <div class="col bg-light-warning ttbox rounded-xl mr-7">
                  <span
                    class="svg-icon svg-icon-3x svg-icon-warning d-block my-2"
                  >
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/ticket.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  <div class="ttnumbr">
                    <template v-if="countLoading">
                      <v-progress-circular
                        indeterminate
                        color="warning"
                      ></v-progress-circular>
                    </template>
                    <template v-else>
                      {{ visitCount.total_visits }}
                    </template>
                  </div>
                  <router-link
                    :to="
                      getDefaultRoute('visit', {
                        query: {
                          status: 'all',
                          tab: 'all',
                        },
                      })
                    "
                    class="text-warning font-weight-bold font-size-h6"
                    >Total Visits</router-link
                  >
                </div>
                <div class="col bg-light-primary ttbox rounded-xl">
                  <span
                    class="svg-icon svg-icon-3x svg-icon-primary d-block my-2"
                  >
                    <inline-svg
                      :src="$assetURL('media/custom-svg/tickets.svg')"
                    />
                  </span>
                  <div class="ttnumbr">
                    <template v-if="countLoading">
                      <v-progress-circular
                        indeterminate
                        color="primary"
                      ></v-progress-circular>
                    </template>
                    <template v-else>
                      {{ visitCount.total_open_visits }}
                    </template>
                  </div>
                  <router-link
                    :to="
                      getDefaultRoute('visit', {
                        query: {
                          status: 'open',
                          tab: 'all',
                        },
                      })
                    "
                    class="text-primary font-weight-bold font-size-h6"
                    >Total Open Visits</router-link
                  >
                </div>
              </div>
              <!--end::Row-->
              <!--begin::Row-->
              <div class="row">
                <div class="col bg-light-info ttbox rounded-xl mr-7">
                  <span class="svg-icon svg-icon-3x svg-icon-info d-block">
                    <inline-svg
                      :src="$assetURL('media/custom-svg/close-t.svg')"
                    />
                  </span>
                  <div class="ttnumbr">
                    <template v-if="countLoading">
                      <v-progress-circular
                        indeterminate
                        color="info"
                      ></v-progress-circular>
                    </template>
                    <template v-else>
                      {{ visitCount.total_completed_visits }}
                    </template>
                  </div>
                  <router-link
                    :to="
                      getDefaultRoute('visit', {
                        query: {
                          status: 'all',
                          tab: 'completed',
                        },
                      })
                    "
                    class="text-info font-weight-bold font-size-h6"
                    >Total Completed Visits</router-link
                  >
                </div>
                <div class="col bg-light-success ttbox rounded-xl">
                  <span
                    class="svg-icon svg-icon-3x svg-icon-success d-block my-2"
                  >
                    <inline-svg
                      :src="$assetURL('media/custom-svg/delete.svg')"
                    />
                  </span>
                  <div class="ttnumbr">
                    <template v-if="countLoading">
                      <v-progress-circular
                        indeterminate
                        color="success"
                      ></v-progress-circular>
                    </template>
                    <template v-else>
                      {{ visitCount.total_cancelled_visits }}
                    </template>
                  </div>
                  <router-link
                    :to="
                      getDefaultRoute('visit', {
                        query: {
                          status: 'cancel',
                          tab: 'all',
                        },
                      })
                    "
                    class="text-success font-weight-bold font-size-h6"
                    >Total Cancel Visits</router-link
                  >
                </div>
              </div>
              <!--end::Row-->

              <!--begin::Row-->
              <div class="row m-0">
                <div class="col bg-light-danger ttbox rounded-xl">
                  <span class="svg-icon svg-icon-3x svg-icon-danger d-block">
                    <inline-svg :src="$assetURL('media/custom-svg/tt1.svg')" />
                  </span>
                  <div class="ttnumbr">
                    <template v-if="countLoading">
                      <v-progress-circular
                        indeterminate
                        color="red"
                      ></v-progress-circular>
                    </template>
                    <template v-else>
                      {{ visitCount.total_overdue_visits }}
                    </template>
                  </div>
                  <router-link
                    :to="
                      getDefaultRoute('visit', {
                        query: {
                          status: 'all',
                          tab: 'overdue',
                        },
                      })
                    "
                    class="text-danger font-weight-bold font-size-h6"
                    >Total Overdue Visits</router-link
                  >
                </div>
                <div v-if="false" class="col bg-light-warning ttbox rounded-xl">
                  <span class="svg-icon svg-icon-3x svg-icon-success d-block">
                    <inline-svg :src="$assetURL('media/custom-svg/bug.svg')" />
                  </span>
                  <div class="ttnumbr">
                    <template v-if="countLoading">
                      <v-progress-circular
                        indeterminate
                        color="success"
                      ></v-progress-circular>
                    </template>
                    <template v-else>
                      {{ bugCount }}
                    </template>
                  </div>
                  <router-link
                    :to="getDefaultRoute('bug.report')"
                    class="text-success font-weight-bold font-size-h6"
                    >Bug Reports</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end::Mixed Widget 1-->
      </v-col>
      <v-col md="4">
        <div class="card dash_card card-custom gutter-b">
          <div class="card-body p-0">
            <div class="cardtopbar">
              <div
                class="float-right custom-menu-select py-1 btn btn-success btn-shadow-hover"
              >
                <v-select
                  :items="durationList"
                  v-model="customerDuration"
                  hide-details
                  :disabled="customerCountLoading"
                  item-color="cyan"
                  color="cyan"
                  class="pt-0 mt-0"
                  v-on:change="getCustomerCount"
                >
                </v-select>
              </div>
              <h4>CUSTOMERS</h4>
              <span class="text-black mt-3 font-weight-400 font-size-sm"
                >You have
                <template v-if="customerCountLoading">
                  <v-progress-circular
                    indeterminate
                    size="14"
                    width="2"
                  ></v-progress-circular>
                </template>
                <template v-else>
                  {{ customerCount.count }}
                </template>
                new customers {{ lodash.lowerCase(customerDuration) }}</span
              >
            </div>
            <div
              id="kt_stats_widget_11_chart"
              class="card-rounded-bottom mid_part"
              data-color="danger"
            >
              <div
                class="d-flex align-items-center justify-content-between card-spacer pb-2 flex-grow-1"
              >
                <span
                  class="symbol symbol-circle symbol-50 symbol-light-danger mr-2"
                >
                  <span class="symbol-label">
                    <span class="svg-icon svg-icon-xl svg-icon-success">
                      <inline-svg
                        :src="$assetURL('media/custom-svg/men.svg')"
                      />
                    </span>
                  </span>
                </span>
                <div class="d-flex flex-column text-right">
                  <span class="qnt_val font-weight-bolder text-success">
                    <template v-if="customerCountLoading">
                      <v-progress-circular
                        indeterminate
                        color="success"
                      ></v-progress-circular>
                    </template>
                    <template v-else>{{ customerCount.percentage }} %</template>
                  </span>
                  <span class="text-muted font-weight-bold mt-2"
                    >more than
                    {{
                      lodash.replace(
                        lodash.lowerCase(customerDuration),
                        "this",
                        "last"
                      )
                    }}</span
                  >
                </div>
              </div>
              <div class="active_mem">
                <ul>
                  <li>
                    All Customers
                    <span class="bg-light-warning">
                      <template v-if="customerCountLoading">
                        <v-progress-circular
                          indeterminate
                          size="24"
                          color="warning"
                        ></v-progress-circular>
                      </template>
                      <template v-else>
                        {{ customerCount.all_customers }}
                      </template>
                    </span>
                  </li>
                  <li>
                    Active Customers
                    <span class="bg-light-success"
                      ><template v-if="customerCountLoading">
                        <v-progress-circular
                          indeterminate
                          size="24"
                          color="success"
                        ></v-progress-circular>
                      </template>
                      <template v-else>
                        {{ customerCount.active_customers }}
                      </template></span
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="card dash_card card-custom gutter-b">
          <div class="card-body p-0">
            <div class="cardtopbar">
              <div
                class="float-right custom-menu-select py-1 btn btn-warning btn-shadow-hover"
              >
                <v-select
                  :items="durationList"
                  v-model="jobDuration"
                  hide-details
                  :disabled="jobCountLoading"
                  item-color="cyan"
                  color="cyan"
                  class="pt-0 mt-0"
                  v-on:change="getJobsCount"
                >
                </v-select>
              </div>
              <h4>VISITS</h4>
              <span class="text-black mt-3 font-weight-400 font-size-sm"
                >You have
                <template v-if="jobCountLoading">
                  <v-progress-circular
                    indeterminate
                    size="14"
                    width="2"
                  ></v-progress-circular>
                </template>
                <template v-else>
                  {{ jobsCount.count }}
                </template>
                new visit scheduled for
                {{ lodash.lowerCase(jobDuration) }}</span
              >
            </div>
            <div
              id="kt_stats_widget_11_chart"
              class="card-rounded-bottom mid_part"
              data-color="danger"
            >
              <div
                class="d-flex align-items-center justify-content-between card-spacer pb-2 flex-grow-1"
              >
                <span
                  class="symbol symbol-circle symbol-50 symbol-light-danger mr-2"
                >
                  <span class="symbol-label">
                    <span class="svg-icon svg-icon-xl svg-icon-warning">
                      <inline-svg
                        :src="$assetURL('media/custom-svg/ticket.svg')"
                      />
                    </span>
                  </span>
                </span>
                <div class="d-flex flex-column text-right">
                  <span class="qnt_val font-weight-bolder text-warning">
                    <template v-if="jobCountLoading">
                      <v-progress-circular
                        indeterminate
                        color="warning"
                      ></v-progress-circular>
                    </template>
                    <template v-else>{{ jobsCount.percentage }} %</template>
                  </span>
                  <span class="text-muted font-weight-bold mt-2"
                    >more than
                    {{
                      lodash.replace(
                        lodash.lowerCase(jobDuration),
                        "this",
                        "last"
                      )
                    }}</span
                  >
                </div>
              </div>
              <div class="active_mem">
                <ul>
                  <li>
                    All Visits
                    <span class="bg-light-warning">
                      <template v-if="jobCountLoading">
                        <v-progress-circular
                          indeterminate
                          size="24"
                          color="warning"
                        ></v-progress-circular>
                      </template>
                      <template v-else>
                        {{ visitCount.total_visits }}
                      </template>
                    </span>
                  </li>
                  <li>
                    Recurring Visits
                    <span class="bg-light-success"
                      ><template v-if="jobCountLoading">
                        <v-progress-circular
                          indeterminate
                          size="24"
                          color="success"
                        ></v-progress-circular>
                      </template>
                      <template v-else>
                        {{ jobsCount.recurring_jobs }}
                      </template></span
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </v-col>
      <v-col md="4">
        <div v-if="false" class="card card-custom gutter-b dash_card">
          <div class="card-body p-0">
            <div class="cardtopbar">
              <div
                class="float-right custom-menu-select py-1 btn btn-primary btn-shadow-hover"
              >
                <v-select
                  :items="durationList"
                  v-model="quotationDuration"
                  hide-details
                  :disabled="quotationCountLoading"
                  item-color="cyan"
                  class="pt-0 mt-0"
                  v-on:change="getQuotationCount"
                >
                </v-select>
              </div>
              <h4>INVOICES</h4>
              <span class="text-black mt-3 font-weight-400 font-size-sm"
                >You have
                <template v-if="quotationCountLoading">
                  <v-progress-circular
                    indeterminate
                    size="14"
                    width="2"
                  ></v-progress-circular>
                </template>
                <template v-else>
                  {{ quotationCount.count }}
                </template>
                new invoices {{ lodash.lowerCase(quotationDuration) }}</span
              >
            </div>
            <div
              id="kt_stats_widget_11_chart"
              class="card-rounded-bottom mid_part"
              data-color="danger"
            >
              <div
                class="d-flex align-items-center justify-content-between card-spacer pb-2 flex-grow-1"
              >
                <span
                  class="symbol symbol-circle symbol-50 symbol-light-danger mr-2"
                >
                  <span class="symbol-label">
                    <span class="svg-icon svg-icon-xl svg-icon-primary">
                      <inline-svg
                        :src="$assetURL('media/custom-svg/clipboard.svg')"
                      />
                    </span>
                  </span>
                </span>
                <div class="d-flex flex-column text-right">
                  <span class="qnt_val font-weight-bolder text-primary">
                    <template v-if="quotationCountLoading">
                      <v-progress-circular
                        indeterminate
                        color="primary"
                      ></v-progress-circular>
                    </template>
                    <template v-else
                      >{{ quotationCount.percentage }} %</template
                    >
                  </span>
                  <span class="text-muted font-weight-bold mt-2"
                    >more than
                    {{
                      lodash.replace(
                        lodash.lowerCase(quotationDuration),
                        "this",
                        "last"
                      )
                    }}</span
                  >
                </div>
              </div>
              <div class="active_mem">
                <ul>
                  <li>
                    Average Revenue
                    <span class="bg-light-success"
                      ><template v-if="quotationCountLoading">
                        <v-progress-circular
                          indeterminate
                          size="24"
                          color="success"
                        ></v-progress-circular>
                      </template>
                      <template v-else>
                        {{ quotationCount.average_revenue }}
                      </template></span
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="card card-custom gutter-b dash_card">
          <div class="card-body p-0">
            <div class="cardtopbar">
              <h4>VISITS DUE TIMES</h4>
            </div>
            <div
              id="kt_stats_widget_11_chart"
              class="card-rounded-bottom mid_part"
              data-color="danger"
            >
              <div
                class="dash_bottom d-flex align-items-center justify-content-between card-spacer pb-2 flex-grow-1"
              >
                <span
                  class="symbol symbol-circle symbol-50 symbol-light-danger mr-2"
                >
                  <span class="symbol-label">
                    <span class="svg-icon svg-icon-xl svg-icon-danger">
                      <inline-svg
                        :src="$assetURL('media/custom-svg/clock.svg')"
                      />
                    </span>
                  </span>
                </span>
                <div class="d-flex flex-column text-right v_list">
                  <ul>
                    <li>
                      <span class="svg-icon svg-icon-xl svg-icon-danger">
                        <inline-svg
                          :src="$assetURL('media/custom-svg/right.svg')"
                        />
                      </span>
                      <strong>In-Progress</strong>
                      <div class="vdnum" v-if="countLoading">
                        <v-progress-circular
                          indeterminate
                          size="24"
                          color="red"
                        ></v-progress-circular>
                      </div>
                      <span v-else>
                        {{ visitCount.total_inprogress_visits }}
                      </span>
                    </li>
                    <li>
                      <span class="svg-icon svg-icon-xl svg-icon-danger">
                        <inline-svg
                          :src="$assetURL('media/custom-svg/right.svg')"
                        />
                      </span>
                      <strong>Today</strong>
                      <div class="vdnum" v-if="countLoading">
                        <v-progress-circular
                          indeterminate
                          size="24"
                          color="red"
                        ></v-progress-circular>
                      </div>
                      <span v-else>
                        {{ visitCount.total_today_visits }}
                      </span>
                    </li>
                    <li>
                      <span class="svg-icon svg-icon-xl svg-icon-danger">
                        <inline-svg
                          :src="$assetURL('media/custom-svg/right.svg')"
                        />
                      </span>
                      <strong>Tomorrow</strong>
                      <div class="vdnum" v-if="countLoading">
                        <v-progress-circular
                          indeterminate
                          size="24"
                          color="red"
                        ></v-progress-circular>
                      </div>
                      <span v-else>
                        {{ visitCount.total_tomorrow_visits }}
                      </span>
                    </li>
                    <li>
                      <span class="svg-icon svg-icon-xl svg-icon-danger">
                        <inline-svg
                          :src="$assetURL('media/custom-svg/right.svg')"
                        />
                      </span>
                      <strong>This week</strong>
                      <div class="vdnum" v-if="countLoading">
                        <v-progress-circular
                          indeterminate
                          size="24"
                          color="red"
                        ></v-progress-circular>
                      </div>
                      <span v-else>
                        {{ visitCount.total_this_week_visits }}
                      </span>
                    </li>
                    <li>
                      <span class="svg-icon svg-icon-xl svg-icon-danger">
                        <inline-svg
                          :src="$assetURL('media/custom-svg/right.svg')"
                        />
                      </span>
                      <strong>Upcoming</strong>
                      <div class="vdnum" v-if="countLoading">
                        <v-progress-circular
                          indeterminate
                          size="24"
                          color="red"
                        ></v-progress-circular>
                      </div>
                      <span v-else>
                        {{ visitCount.total_upcomming_visits }}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-col>
      <v-col md="12" sm="12" class="{'d-none': !overdue_visits}">
        <DashboardVisits
          v-on:init-component="overdue_visits = true"
        ></DashboardVisits>
      </v-col>
      <v-col md="6" sm="12">
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title justify-space-between width-100">
              <h3 class="card-label">PRIORITY-WISE VISITS</h3>
              <div
                class="text-right custom-menu-select py-1 btn btn-primary btn-shadow-hover"
              >
                <v-select
                  :items="durationList"
                  v-model="priorityDuration"
                  hide-details
                  :disabled="priorityCountLoading"
                  item-color="cyan"
                  class="pt-0 mt-0"
                  v-on:change="getPriorityGraph"
                >
                </v-select>
              </div>
            </div>
          </div>
          <div class="card-body">
            <template v-if="priorityCountLoading">
              <div class="min-height-400px d-flex align-center">
                <v-progress-linear
                  color="primary"
                  indeterminate
                  rounded
                  height="6"
                ></v-progress-linear>
              </div>
            </template>
            <template v-else>
              <apexchart
                height="455"
                type="area"
                :options="priorityChartoptions"
                :series="prioritySeries"
              ></apexchart>
            </template>
          </div>
        </div>
      </v-col>
      <v-col md="6" sm="12">
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title justify-space-between width-100">
              <h3 class="card-label">ISSUE-WISE VISITS</h3>
              <div
                class="text-right custom-menu-select py-1 btn btn-primary btn-shadow-hover"
              >
                <v-select
                  :items="durationList"
                  v-model="issueDuration"
                  hide-details
                  :disabled="issueCountLoading"
                  item-color="cyan"
                  class="pt-0 mt-0"
                  v-on:change="getIssueGraph"
                >
                </v-select>
              </div>
            </div>
          </div>
          <div class="card-body">
            <template v-if="issueCountLoading">
              <div class="min-height-400px d-flex align-center">
                <v-progress-linear
                  color="primary"
                  indeterminate
                  rounded
                  height="6"
                ></v-progress-linear>
              </div>
            </template>
            <template v-else>
              <apexchart
                height="468"
                type="pie"
                :options="issueChartoptions"
                :series="issueSeries"
              ></apexchart>
            </template>
          </div>
        </div>
      </v-col>
    </v-row>
    <template>
      <template v-if="customerDialog">
        <CustomerDialog
          :customerDialog="customerDialog"
          v-on:closeDialog="closeDialog"
          v-on:resetAll="resetAll"
          v-on:selectCustomer="selectCustomer"
          v-on:selectCustomerPerson="selectCustomerPerson"
          v-on:selectCustomerProperty="selectCustomerProperty"
          v-on:selectCustomerBilling="selectCustomerBilling"
        ></CustomerDialog>
      </template>
      <template v-if="customerPersonDialog">
        <CustomerPersonDialog
          :customerPersonDialog="customerPersonDialog"
          :customer="customer"
          v-on:closeDialog="closeDialog"
          v-on:resetAll="resetAll"
          v-on:selectCustomerPerson="selectCustomerPerson"
        ></CustomerPersonDialog>
      </template>
      <template v-if="customerPropertyDialog">
        <CustomerPropertyDialog
          :customerPropertyDialog="customerPropertyDialog"
          :customer="customer"
          v-on:closeDialog="closeDialog"
          v-on:resetAll="resetAll"
          v-on:selectCustomerProperty="selectCustomerProperty"
        ></CustomerPropertyDialog>
      </template>
    </template>
  </v-container>
</template>

<style scoped src="@/assets/sass/dashboard.scss" lang="scss"></style>
<script>
const colorPalette = ["#00D8B6", "#008FFB", "#FEB019", "#FF4560", "#775DD0"];

import { GET, QUERY } from "@/core/services/store/request.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CustomerDialog from "@/view/pages/partials/Select-Customer.vue";
import CustomerPersonDialog from "@/view/pages/partials/Select-Customer-Person.vue";
import CustomerPropertyDialog from "@/view/pages/partials/Select-Customer-Property.vue";
import DashboardVisits from "@/view/pages/partials/Dashboard-Visits.vue";

export default {
  name: "dashboard",
  data() {
    return {
      customer: 0,
      customerPerson: 0,
      customerProperty: 0,
      customerBilling: 0,
      customerDialog: false,
      customerPersonDialog: false,
      customerPropertyDialog: false,
      transactionType: null,
      overdue_visits: false,
      prioritySeries: [],
      priorityChartoptions: {
        stroke: {
          curve: "straight",
        },
        xaxis: {
          categories: [],
        },
        tooltip: {
          followCursor: true,
        },
        fill: {
          opacity: 1,
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
        },
      },
      issueSeries: [],
      issueChartoptions: {
        dataLabels: {
          enabled: false,
        },
        colors: colorPalette,
        labels: [],
        legend: {
          position: "left",
        },
      },
      issueDuration: "this_month",
      issueCountLoading: false,
      priorityDuration: "this_month",
      priorityCountLoading: false,
      countLoading: true,
      customerCountLoading: true,
      jobCountLoading: true,
      quotationCountLoading: true,
      durationList: [
        { text: "This Week", value: "this_week" },
        { text: "This Month", value: "this_month" },
        { text: "This Year", value: "this_year" },
      ],
      customerDuration: "this_month",
      jobDuration: "this_month",
      quotationDuration: "this_month",
      bugCount: 0,
      customerCount: {
        count: 0,
        all_customers: 0,
        active_customers: 0,
        percentage: 0,
      },
      quotationCount: {
        count: 0,
        percentage: 0,
        average_revenue: 0,
      },
      jobsCount: {
        count: 0,
        all_jobs: 0,
        recurring_jobs: 0,
        percentage: 0,
      },
      jobCount: {
        total_jobs: 0,
        total_open_jobs: 0,
        total_closed_jobs: 0,
        total_cancelled_jobs: 0,
      },
      visitCount: {
        total_visits: 0,
        total_open_visits: 0,
        total_overdue_visits: 0,
        total_today_visits: 0,
        total_tomorrow_visits: 0,
        total_this_week_visits: 0,
        total_upcomming_visits: 0,
        total_inprogress_visits: 0,
        total_hold_visits: 0,
        total_completed_visits: 0,
        total_cancelled_visits: 0,
      },
    };
  },
  methods: {
    createTransaction(type) {
      this.transactionType = type;
      this.customerDialog = true;
    },
    resetAll() {
      this.customer = 0;
      this.customerPerson = 0;
      this.customerProperty = 0;
      this.closeDialog();
    },
    closeDialog() {
      this.customerDialog = false;
      this.customerPersonDialog = false;
      this.customerPropertyDialog = false;
    },
    selectCustomer(param) {
      this.customer = param;
      this.closeDialog();
      this.checkTransactionType();
      if (!this.customerPerson || this.customerPerson <= 0) {
        this.customerPersonDialog = true;
      } else {
        this.customerPropertyDialog = true;
      }
    },
    selectCustomerPerson(param) {
      this.customerPerson = param;
      this.closeDialog();
      this.checkTransactionType();
      if (
        (!this.customerProperty || this.customerProperty <= 0) &&
        this.transactionType != "quotation"
      ) {
        this.customerPropertyDialog = true;
      }
    },
    selectCustomerProperty(param) {
      this.customerProperty = param;
      this.closeDialog();
      this.checkTransactionType();
    },
    selectCustomerBilling(param) {
      this.customerBilling = param;
      this.closeDialog();
      this.checkTransactionType();
    },
    checkTransactionType() {
      const _this = this;
      if (_this.transactionType === "quotation") {
        if (
          _this.customer > 0 &&
          _this.customerPerson > 0 &&
          _this.customerBilling > 0
        ) {
          _this.$router.push(
            _this.getDefaultRoute("quotation.create", {
              query: {
                customer: _this.customer,
                contact_person: _this.customerPerson,
                billing: _this.customerBilling,
              },
            })
          );
        }
      } else {
        if (
          _this.customer > 0 &&
          _this.customerPerson > 0 &&
          _this.customerProperty > 0
        ) {
          _this.$router.push(
            _this.getDefaultRoute(_this.transactionType + ".create", {
              query: {
                customer: _this.customer,
                contact_person: _this.customerPerson,
                property: _this.customerProperty,
              },
            })
          );
        }
      }
    },
    getDashboardCount() {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(GET, {
              url: "dashboard-count",
            })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    getDashboardCustomerCount() {
      const _this = this;
      _this.customerCountLoading = true;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(QUERY, {
              url: "dashboard-customer",
              data: {
                duration: _this.customerDuration,
              },
            })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    getDashboardJobCount() {
      const _this = this;
      _this.jobCountLoading = true;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(QUERY, {
              url: "dashboard-job",
              data: {
                duration: _this.jobDuration,
              },
            })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    getDashboardQuotationCount() {
      const _this = this;
      _this.quotationCountLoading = true;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(QUERY, {
              url: "dashboard-quotation",
              data: {
                duration: _this.quotationDuration,
              },
            })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    getDashboardPriorityCount() {
      const _this = this;
      _this.priorityCountLoading = true;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(QUERY, {
              url: "dashboard-priority-graph",
              data: {
                duration: _this.priorityDuration,
              },
            })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    getDashboardIssueCount() {
      const _this = this;
      _this.issueCountLoading = true;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(QUERY, {
              url: "dashboard-issue-graph",
              data: {
                duration: _this.issueDuration,
              },
            })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    getCustomerCount() {
      const _this = this;
      _this
        .getDashboardCustomerCount()
        .then(({ customer }) => {
          if (customer) {
            _this.customerCount = customer;
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.customerCountLoading = false;
        });
    },
    getJobsCount() {
      const _this = this;
      _this
        .getDashboardJobCount()
        .then(({ job }) => {
          if (job) {
            _this.jobsCount = job;
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.jobCountLoading = false;
        });
    },
    getQuotationCount() {
      const _this = this;
      _this
        .getDashboardQuotationCount()
        .then(({ quotation }) => {
          if (quotation) {
            _this.quotationCount = quotation;
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.quotationCountLoading = false;
        });
    },
    getPriorityGraph() {
      const _this = this;
      _this
        .getDashboardPriorityCount()
        .then(({ categories, prioritySeries }) => {
          if (categories) {
            _this.priorityChartoptions.xaxis.categories = categories;
          }
          if (prioritySeries) {
            _this.prioritySeries = prioritySeries;
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.priorityCountLoading = false;
        });
    },
    getIssueGraph() {
      const _this = this;
      _this
        .getDashboardIssueCount()
        .then(({ issueSeries, labels }) => {
          if (labels) {
            _this.issueChartoptions.labels = labels;
          }
          if (issueSeries) {
            _this.issueSeries = issueSeries;
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.issueCountLoading = false;
        });
    },
  },
  components: {
    CustomerDialog,
    DashboardVisits,
    CustomerPersonDialog,
    CustomerPropertyDialog,
  },
  mounted() {
    const _this = this;
    _this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Dashboard",
      },
    ]);
    _this.getCustomerCount();
    _this.getJobsCount();
    _this.getQuotationCount();
    _this.getPriorityGraph();
    _this.getIssueGraph();
    _this
      .getDashboardCount()
      .then(({ job, visit, bug }) => {
        if (job) {
          _this.jobCount = job;
        }
        if (visit) {
          _this.visitCount = visit;
        }
        if (bug) {
          _this.bugCount = bug;
        }
      })
      .catch((error) => {
        _this.logError(error);
      })
      .finally(() => {
        _this.countLoading = false;
      });
  },
};
</script>
